import { 
  SAVE_WIDEVINE_LICENSE_CONFIG, 
  SAVE_WIDEVINE_LICENSE_CONFIG_START,
  SAVE_WIDEVINE_LICENSE_CONFIG_SUCCESS,
  SAVE_WIDEVINE_LICENSE_CONFIG_FAIL
} from './constants';

export const saveWidevineLicenseConfig = (configurationId, formData) => ({
  type: SAVE_WIDEVINE_LICENSE_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const saveWidevineLicenseConfigStart = () => ({
  type: SAVE_WIDEVINE_LICENSE_CONFIG_START,
});

export const saveWidevineLicenseConfigSuccess = (configurationId, formData) => ({
  type: SAVE_WIDEVINE_LICENSE_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveWidevineLicenseConfigFaill = () => ({
  type: SAVE_WIDEVINE_LICENSE_CONFIG_FAIL,
});
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AdminLayout } from "./layouts/AdminLayout";

import './scss/App.scss';
import './scss/App.css';
import AmplifyAuth from './components/Auth/AmplifyAuth';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react'
import awsExports from './config/aws-exports';
import Amplify from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

Amplify.configure(awsExports);

const App = () => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <React.Fragment>
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/home" />
      </Switch>
    </React.Fragment>
    ) : (
      <React.Fragment>
        <Switch>
          <Route path="/" render={props => {
            return (
              <AmplifyAuthenticator>
                <AmplifyAuth />
                  {
                    authState && (authState && (user || (authState !== AuthState.SignedIn))) ? <Redirect to="/" /> : null
                  }
              </AmplifyAuthenticator>
            )
          }} />
        </Switch>
      </React.Fragment>
  );
};

export default App;

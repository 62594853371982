import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify';
import { navbarBorder } from './AdminNavbar.module.scss';

const AdminNavbar = ({ brandText }) => {
  
  const [username, setUsername] = useState("");
  
  Auth.currentAuthenticatedUser().then(res => setUsername(res.username));

  return (
    <>
      <Navbar className={classnames("navbar-top navbar-dark d-none d-md-inline-block bg-gradient-info", navbarBorder)} expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-md-inline-block"
            to="/"
          >
            { brandText }
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {/* <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      // src={require("src/images/cyber-security.jpg")}
                    />
                  </span> */}
                  <Media className="ml-2 d-none d-md-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="sign-out-button" onClick={e => e.preventDefault()}>
                  <div><AmplifySignOut /></div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;

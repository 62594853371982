import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import store from './store';

import "argon-dashboard-react/src/assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./scss/resources.scss";

import App from './App';
import * as serviceWorker from './serviceWorker';
import { MarketplaceLayout } from './layouts/MarketplaceLayout';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/marketplace" render={props => <MarketplaceLayout {...props} />} />
        <Route path="/" render={props => <App {...props} />} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister();

import React from 'react';
import { Row, Card, CardHeader, CardBody } from 'reactstrap';
import Header from '../../../components/Marketplace/Header';
import Descriptions from '../../../components/Marketplace/Descriptions';
import ContactForm from '../../../components/Marketplace/ContactForm';
import { en } from './resources'; 

const DRMFulfilmentPage = ({ history }) => {
  const { header: { title, subtitle }, descriptions, contanctForm: { checkboxAgreement } } = en;
  return (
    <Card className="card-register card-plain">
      <CardHeader className="pb-0 border-bottom-0 text-center">
        <Row className="justify-content-center">
          <Header title={title} subtitle={subtitle} />
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Descriptions descriptions={descriptions} />
          <ContactForm checkboxAgreement={checkboxAgreement} history={history} />
        </Row>
      </CardBody>
    </Card>
  )
};

export default DRMFulfilmentPage;
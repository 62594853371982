import React from 'react';
import { Route, Switch } from "react-router-dom";

const LayoutRouter = ({ routes, layout }) => (
  <Switch>
    {
      routes.map((prop, key) => {
        if (prop.layout === layout) {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      })
    }
  </Switch>
);

export default LayoutRouter; 
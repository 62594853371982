import { selectIds, convertListToObjectById } from '../../../utils';
import { assign } from 'object-path-immutable';

import { FETCH_DRM_CONFIGURATION_SUCCESS, FETCH_DRM_CONFIGURATIONS_SUCCESS } from './';
import { SAVE_DRM_GENERAL_INFO_SUCCESS } from '../GeneralInfo';
import { SAVE_LICENSE_DURATION_CONFIGURATION_SUCCESS } from '../LicenseDuration';
import { SAVE_FAIRPLAY_SYSTEM_CONFIG_SUCCESS } from '../Fairplay/SystemConfig';
import { SAVE_FAIRPLAY_LICENSE_CONFIG_SUCCESS } from '../Fairplay/LicenseConfig';
import { SAVE_WIDEVINE_SYSTEM_CONFIG_SUCCESS } from '../Widevine/SystemConfig';
import { SAVE_WIDEVINE_LICENSE_CONFIG_SUCCESS } from '../Widevine/LicenseConfig';
import { SAVE_PLAYREADY_SYSTEM_CONFIG_SUCCESS } from '../Playready/SystemConfig';
import { SAVE_PLAYREADY_LICENSE_CONFIG_SUCCESS } from '../Playready/LicenseConfig';
import { ADD_PLAYER_ENABLER_SUCCESS, DELETE_PLAYER_ENABLER_SUCCESS } from '../Playready/PlayerEnablers';
import { 
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS, 
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS
} from '../Playready/AnalogVideoOutputProtections';

import {
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS
} from '../Playready/DigitalAudioOutputProtections';

import {
  ADD_LICENSE_RULE_SUCCESS
} from '../Rules/LicenseRules';

import {
  updateGeneralInfo,
  updateLicenseDurationConfiguration,
  updatePlayReadySystemConfig,
  updatePlayReadyLicenseConfig,
  addPlayerEnablers,
  deletePlayerEnablers,
  addAnalogVideoOutputProtections,
  editAnalogVideoOutputProtections,
  deleteAnalogVideoOutputProtections,
  addDigitalAudioOutputProtectionList,
  editDigitalAudioOutputProtectionList,
  deleteDigitalAudioOutputProtectionList,
  updateWidevineSystemConfig,
  updateWidevineLicenseConfig,
  updateFairPlaySystemConfig,
  updateFairplayLicenseConfig,
  addLicenseRule
} from '.';

const initialState = {
  isFetched: false,
  configurationsIds: [],
  configurationsById: {}
};

const drmConfigurations = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_DRM_CONFIGURATION_SUCCESS:
      return {
        ...state, 
        ...action.payload,
        isFetched: true
      }
    
    case FETCH_DRM_CONFIGURATIONS_SUCCESS: {
      return assign(state, "", {
        isFetched: true,
        configurationsIds: [...selectIds(action.payload)],
        configurationsById: {...convertListToObjectById(action.payload)}       
      });
    }
    
    case SAVE_DRM_GENERAL_INFO_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateGeneralInfo(state, formData, configurationId);
    }
    
    case SAVE_LICENSE_DURATION_CONFIGURATION_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateLicenseDurationConfiguration(state, formData, configurationId);
    }

    case SAVE_FAIRPLAY_SYSTEM_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateFairPlaySystemConfig(state, formData, configurationId);
    }

    case SAVE_FAIRPLAY_LICENSE_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateFairplayLicenseConfig(state, formData, configurationId);
    }

    case SAVE_WIDEVINE_SYSTEM_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateWidevineSystemConfig(state, formData, configurationId);
    }
      
    case SAVE_WIDEVINE_LICENSE_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updateWidevineLicenseConfig(state, formData, configurationId);
    }

    case SAVE_PLAYREADY_SYSTEM_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updatePlayReadySystemConfig(state, formData, configurationId);
    }
    
    case SAVE_PLAYREADY_LICENSE_CONFIG_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return updatePlayReadyLicenseConfig(state, formData, configurationId);
    }
    
    case ADD_PLAYER_ENABLER_SUCCESS: {
      const { configurationId, formData } = action.payload;
      return addPlayerEnablers(state, formData, configurationId);
    }
    
    case DELETE_PLAYER_ENABLER_SUCCESS: {
      const { configurationId, formData } = action.payload;     
      return deletePlayerEnablers(state, formData, configurationId);
    }

    case ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return addAnalogVideoOutputProtections(state, formData, configurationId);
    }
    
    case EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return editAnalogVideoOutputProtections(state, formData, configurationId);
    }
    
    case DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return deleteAnalogVideoOutputProtections(state, formData, configurationId);
    }
    
    case ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return addDigitalAudioOutputProtectionList(state, formData, configurationId);
    }
    
    case EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return editDigitalAudioOutputProtectionList(state, formData, configurationId);
    }

    case DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return deleteDigitalAudioOutputProtectionList(state, formData, configurationId);
    }

    case ADD_LICENSE_RULE_SUCCESS: {
      const { configurationId, formData } = action.payload; 
      return addLicenseRule(state, formData, configurationId);
    }

    default:
      return state;
  }
};

export default drmConfigurations;
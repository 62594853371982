import { takeEvery, all } from 'redux-saga/effects';

import { 
  FETCH_DRM_CONFIGURATION, 
  fetchDRMConfigurationSaga, 
  FETCH_DRM_CONFIGURATIONS, 
  fetchDRMConfigurationsSaga 
} from './';
import { SAVE_DRM_GENERAL_INFO, saveDRMGeneralInfoSaga } from '../GeneralInfo';
import { SAVE_LICENSE_DURATION_CONFIGURATION, saveLicenseDurationConfigurationSaga } from '../LicenseDuration';
import { SAVE_FAIRPLAY_SYSTEM_CONFIG, saveFairplaySystemConfigSaga } from '../Fairplay/SystemConfig';
import { SAVE_FAIRPLAY_LICENSE_CONFIG, saveFairplayLicenseConfigSaga } from '../Fairplay/LicenseConfig';
import { SAVE_WIDEVINE_SYSTEM_CONFIG, saveWidevineSystemConfigSaga } from '../Widevine/SystemConfig';
import { SAVE_WIDEVINE_LICENSE_CONFIG, saveWidevineLicenseConfigSaga } from '../Widevine/LicenseConfig';
import { SAVE_PLAYREADY_SYSTEM_CONFIG, savePlayreadySystemConfigSaga } from '../Playready/SystemConfig';
import { SAVE_PLAYREADY_LICENSE_CONFIG, savePlayreadyLicenseConfigSaga } from '../Playready/LicenseConfig';
import { ADD_PLAYER_ENABLER, DELETE_PLAYER_ENABLER, addPlayerEnablerSaga, deletePlayerEnablerSaga } from '../Playready/PlayerEnablers';
import { 
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION, 
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION, 
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION,
  addAnalogVideoOutputProtectionSaga, 
  editAnalogVideoOutputProtectionSaga,
  deleteAnalogVideoOutputProtectionSaga
} from '../Playready/AnalogVideoOutputProtections';

import { 
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION, 
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION, 
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION,
  addDigitalAudioOutputProtectionSaga, 
  editDigitalAudioOutputProtectionSaga,
  deleteDigitalAudioOutputProtectionSaga
} from '../Playready/DigitalAudioOutputProtections';

import { ADD_LICENSE_RULE, addLicenseRuleSaga } from '../Rules/LicenseRules';

export function* watchDRM() {
  yield all([
    takeEvery(FETCH_DRM_CONFIGURATION, fetchDRMConfigurationSaga),
    takeEvery(FETCH_DRM_CONFIGURATIONS, fetchDRMConfigurationsSaga),
    takeEvery(SAVE_DRM_GENERAL_INFO, saveDRMGeneralInfoSaga),
    takeEvery(SAVE_LICENSE_DURATION_CONFIGURATION, saveLicenseDurationConfigurationSaga),
    takeEvery(SAVE_FAIRPLAY_SYSTEM_CONFIG, saveFairplaySystemConfigSaga),
    takeEvery(SAVE_FAIRPLAY_LICENSE_CONFIG, saveFairplayLicenseConfigSaga),
    takeEvery(SAVE_WIDEVINE_SYSTEM_CONFIG, saveWidevineSystemConfigSaga),
    takeEvery(SAVE_WIDEVINE_LICENSE_CONFIG, saveWidevineLicenseConfigSaga),
    takeEvery(SAVE_PLAYREADY_SYSTEM_CONFIG, savePlayreadySystemConfigSaga),
    takeEvery(SAVE_PLAYREADY_LICENSE_CONFIG, savePlayreadyLicenseConfigSaga),
    takeEvery(ADD_PLAYER_ENABLER, addPlayerEnablerSaga),
    takeEvery(DELETE_PLAYER_ENABLER, deletePlayerEnablerSaga),
    takeEvery(ADD_ANALOG_VIDEO_OUTPUT_PROTECTION, addAnalogVideoOutputProtectionSaga),
    takeEvery(EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION, editAnalogVideoOutputProtectionSaga),
    takeEvery(DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION, deleteAnalogVideoOutputProtectionSaga),
    takeEvery(ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION, addDigitalAudioOutputProtectionSaga),
    takeEvery(EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION, editDigitalAudioOutputProtectionSaga),
    takeEvery(DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION, deleteDigitalAudioOutputProtectionSaga),
    takeEvery(ADD_LICENSE_RULE, addLicenseRuleSaga)
  ])
}
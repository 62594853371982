import React from 'react';
import { Media as MediaBootstrap } from 'reactstrap';
import classnames from 'classnames';
import { highlightEmails } from '../../utils/formatter';

const Media = ({ header, body, icon, img }) => {
  return (
    <MediaBootstrap className="pb-3">
      <MediaBootstrap left className="text-center">
        <div className="icon">
          { img ? <img src={img.src} style={{ width: img.width ?? 45, height: img.height ?? 47 }} alt='' /> : null } 
          { icon ? <i className={classnames(icon)}></i> : null }
        </div>
      </MediaBootstrap>
      <MediaBootstrap body className="pl-3">
        <h4 className="text-left c-white">{header}</h4>
        <p className="text-left c-white" dangerouslySetInnerHTML={{ __html: highlightEmails(body, '#e60050')}}></p>
      </MediaBootstrap>
    </MediaBootstrap>
  )
}

export default Media;
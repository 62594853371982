import React from 'react';
import { Modal, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  close,
  selectOpen,
  selectTitle,
  selectBody
} from './alertModalSlice';
import { highlightEmails } from '../../utils/formatter';

const AlertModal = () => {
  const isOpen = useSelector(selectOpen);
  const title = useSelector(selectTitle);
  const body = useSelector(selectBody);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(close())
  }

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      toggle={handleClick}
    >
      <div className="modal-header">
        <h4
          className="modal-title"
          id="modal-title-notification"
        >
          Your attention is required
        </h4>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleClick}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">
            { title }
          </h4>
          <p dangerouslySetInnerHTML={{ __html: highlightEmails(body, '#172b4d')}}></p>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={handleClick}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default AlertModal;
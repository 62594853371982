import React from 'react';
import { Col, CardTitle, CardSubtitle } from 'reactstrap';

const Header = ({ title, subtitle }) => {
  return (
    <Col md="10">
      <div className="header-text">
        <CardTitle className="fs-2">{title}</CardTitle>
        <CardSubtitle className="fs-1">{subtitle}</CardSubtitle>
        <hr className="mt-3 mb-3 hr-bt-1" />
      </div>
    </Col>
  );
};

export default Header;
import { push, set, assign } from 'object-path-immutable';
import _ from 'lodash';

const setRoot = (configurationId) => configurationId ? `configurationsById.${configurationId}` : "";

const setNextLevel = (configurationId) => configurationId ? `.` : "";

const getPlayerEnablersPath = (configurationId) => `${setRoot(configurationId)}${setNextLevel(configurationId)}config.playReady.licenseConfig.playerEnablers`;

const getAnalogVideoOutputProtectionListPath = (configurationId) => `${setRoot(configurationId)}${setNextLevel(configurationId)}config.playReady.licenseConfig.analogVideoOutputProtectionList`;

const getDigitalAudioOutputProtectionListPath = (configurationId) => `${setRoot(configurationId)}${setNextLevel(configurationId)}config.playReady.licenseConfig.digitalAudioOutputProtectionList`;

export const updateGeneralInfo = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}`, { ...formData });
  
export const updateLicenseDurationConfiguration = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.licenseDurationConfig`, { ...formData });

export const updatePlayReadySystemConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.playReady.systemConfig`, { ...formData });

export const updatePlayReadyLicenseConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.playReady.licenseConfig`, { ...formData });

export const addPlayerEnablers = (obj, { guid }, configurationId = null) => push(obj, getPlayerEnablersPath(configurationId), guid);

export const deletePlayerEnablers = (obj, { guid }, configurationId = null) => {
  const path = getPlayerEnablersPath(configurationId);
  const items = _.get(obj, path, []).filter(item => item !== guid);
  return set(obj, path, [...items]);
}

export const addAnalogVideoOutputProtections = (obj, formData, configurationId = null) => push(obj, getAnalogVideoOutputProtectionListPath(configurationId), { ...formData });

export const editAnalogVideoOutputProtections = (obj, formData, configurationId = null) => {
  
  const { guid, hexData } = formData;
  const path = getAnalogVideoOutputProtectionListPath(configurationId);
  const items = _.get(obj, path, [])
  const objIndex = items.findIndex((obj => obj.guid === guid));

  items[objIndex].hexData = hexData;
  
  return set(obj, path, [...items]);
}

export const deleteAnalogVideoOutputProtections = (obj, formData, configurationId = null) => {
  
  const { guid } = formData;
  const path = getAnalogVideoOutputProtectionListPath(configurationId);
  const items = _.get(obj, path, []).filter(item => item.guid !== guid);
    
  return set(obj, path, [...items]);
}

export const addDigitalAudioOutputProtectionList = (obj, formData, configurationId = null) => push(obj, getDigitalAudioOutputProtectionListPath(configurationId), { ...formData });

export const editDigitalAudioOutputProtectionList = (obj, formData, configurationId = null) => {
  
  const { guid, hexData } = formData;
  const path = getDigitalAudioOutputProtectionListPath(configurationId);
  const items = _.get(obj, path, [])
  const objIndex = items.findIndex((obj => obj.guid === guid));

  items[objIndex].hexData = hexData;
  
  return set(obj, path, [...items]);
}

export const deleteDigitalAudioOutputProtectionList = (obj, formData, configurationId = null) => {
  
  const { guid } = formData;
  const path = getDigitalAudioOutputProtectionListPath(configurationId);
  const items = _.get(obj, path, []).filter(item => item.guid !== guid);
    
  return set(obj, path, [...items]);
}

export const updateWidevineSystemConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.widevine.systemConfig`, { ...formData });

export const updateWidevineLicenseConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.widevine.licenseConfig`, { ...formData });

export const updateFairPlaySystemConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.fairPlay.systemConfig`, { ...formData });

export const updateFairplayLicenseConfig = (obj, formData, configurationId = null) => assign(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}config.fairPlay.licenseConfig`, { ...formData });

export const addLicenseRule  = (obj, formData, configurationId = null) => push(obj, `${setRoot(configurationId)}${setNextLevel(configurationId)}rules`, { ...formData });
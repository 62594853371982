import { 
  SAVE_FAIRPLAY_SYSTEM_CONFIG, 
  SAVE_FAIRPLAY_SYSTEM_CONFIG_START,
  SAVE_FAIRPLAY_SYSTEM_CONFIG_SUCCESS,
  SAVE_FAIRPLAY_SYSTEM_CONFIG_FAIL
} from './constants';

export const saveFairplaySystemConfig = (configurationId, formData) => ({
  type: SAVE_FAIRPLAY_SYSTEM_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const saveFairplaySystemConfigStart = () => ({
  type: SAVE_FAIRPLAY_SYSTEM_CONFIG_START,
});

export const saveFairplaySystemConfigSuccess = (configurationId, formData) => ({
  type: SAVE_FAIRPLAY_SYSTEM_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveFairplaySystemConfigFaill = () => ({
  type: SAVE_FAIRPLAY_SYSTEM_CONFIG_FAIL,
});
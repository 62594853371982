export const ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION = 'ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION';
export const ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_START = 'ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_START';
export const ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL = 'ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL';

export const EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION = 'EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION';
export const EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_START = 'EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_START';
export const EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL = 'EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL';

export const DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION = 'DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION';
export const DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_START = 'DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_START';
export const DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL = 'DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL';
import React, { useState } from "react";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "reactstrap";
import { isUUID } from '../../../utils/guid';
import { breadcrumb } from './BreadCrumb.module.scss';

const BreadCrumb = ({ url = "", xs = 12, lg = 12 }) => {

  const segments = url?.split('/').filter(x => x !== "").map(x => `/${x}`) ?? [];
  const urls = [];

  for (let i = 0; i < segments.length; i++) {
    let path = ""
    let segment = segments[i].replace('/', '').toLowerCase();

    segment = segment === 'drm' ? "DRM" : segment;
    segment = isUUID(segment) ? segment.split('-')[0] : segment.charAt(0).toUpperCase() + segment.slice(1);
    
    for (let j = 0; j <= i; j++) {
      path = path + segments[j]
    }

    urls.push({
      segment,
      path
    });
  }

  const [paths] = useState(urls);

  return (
    <>
      <nav className={"header header-dark pb-12 bg-gradient-info content__title--calendar"}>  
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg={lg} xs={xs}>
                <Breadcrumb
                  listClassName={classnames("breadcrumb-links breadcrumb-dark", breadcrumb)} 
                >
                  {
                    paths.map((item, key) => {
                      const { path, segment } = item;
                      return (
                        <BreadcrumbItem key={key}>
                          <Link to={path}>
                            { segment.toLowerCase() === "admin" ? <i className="fas fa-home" /> : segment}
                          </Link>
                        </BreadcrumbItem>
                      )
                    })
                  }
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </nav>
    </>
  );
}

export default BreadCrumb;

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../../components/Layout/Navbars/AdminNavbar.js";
import Sidebar from "../../components/Layout/Sidebar/Sidebar.js";

import routes from "./routes";
import menu from './menu';
import Insys from '../../assets/images/insys.png';
import Modal from '../../components/Common/Modal';

import { footerBorder } from './AdminLayout.module.scss';
import './AdminLayout.scss';
import '../../scss/Table.scss';
import '../../scss/Toggle.scss';
import MainFooter from "../../components/Layout/Footers/MainFooter";
import AlertModal from '../../features/alertModal/AlertModal';
class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      } else if (routes[i].views) {
        for (let j = 0; j < routes[i].views.length; j++) {
          if (this.props.location.pathname.indexOf(routes[i].views[j].layout + routes[i].views[j].path) !== -1) {
            return routes[i].views[j].name;
          }   
        }
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={menu}
          logo={{
            innerLink: "/admin/drm/dashboards",
            imgSrc: Insys,
            imgAlt: "Insys Cloud Services"
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/drm/dashboards" />
          </Switch>
          <Container fluid className={footerBorder}>
            <MainFooter />
          </Container>
          <Modal />
          <AlertModal />
        </div>
      </>
    );
  }
}

export default Admin;

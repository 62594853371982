import {
  HIDE_MODAL,
  SHOW_PROCESSING_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_SUCCESS_MODAL,
  ModalStates
} from './constants';

const initialState = {
  modalState: ModalStates.Hide
};

const modal = (state = initialState, action) => {
  switch(action.type) {
    case HIDE_MODAL: 
      return {
        ...state,
        modalState: ModalStates.Hide
      }
    case SHOW_PROCESSING_MODAL:
      return {
        ...state,
        modalState: ModalStates.Processing
      }
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        modalState: ModalStates.Success
      }
      case SHOW_ERROR_MODAL:
        return {
          ...state,
          modalState: ModalStates.Error
        }
    default:
      return state;
  }
};

export default modal;
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_PROCESSING_MODAL = 'SHOW_PROCESSING_MODAL';
export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';

export const ModalStates = {
  Processing: "Processing",
  Error: "Error",
  Success: "Success",
  Hide: "Hide",
  Default: "Default"
};
import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { getYear } from '../../../utils/date';

const MainFooter = () => {
  return (
    <footer className="footer pt-4 pb-4 bc-transparent">
        <Row className="align-items-center justify-content-xl-between">
          <Col lg={{size: 6, order: 12}}>
            <div className="copyright text-center text-lg-right">
              © {getYear()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://insysvideotechnologies.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Insys
              </a>
            </div>
          </Col>
          <Col lg={{size: 6, order: 1}}>
            <Nav className="nav-footer justify-content-center justify-content-lg-start">
              <NavItem>
                <NavLink
                  href="https://insysvideotechnologies.com/solutions"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Solutions
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://insysvideotechnologies.com/company"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  About Us
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://insysvideotechnologies.com/news"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  News
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
  );
}

export default MainFooter;

import { selectIds } from '../../../utils';
import { assign } from 'object-path-immutable';

import { 
  FETCH_DRM_DASHBOARDS_SUCCESS, 
  FETCH_DRM_DASHBOARD_SUCCESS 
} from './constants';

const initialState = {
  isFetched: false,
  dashboardsIds: [],
  dashboards: [],
  dashboardsById: {}
};

const drmDashboards = (state = initialState, action) => {
  switch(action.type) {
    case FETCH_DRM_DASHBOARDS_SUCCESS: {
      return assign(state, "", {
        isFetched: true,
        dashboardsIds: [...selectIds(action.payload)],
        dashboards: [...action.payload]
      });
    }
    case FETCH_DRM_DASHBOARD_SUCCESS: {
      const dashboard = action.payload
      return assign(state, "dashboardsById", {
        [dashboard.dashboardId]: dashboard
      });
    }
    default:
      return state;
  }
};

export default drmDashboards;
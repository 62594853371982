import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone, PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import { Card, CardHeader, Row, Col, CardFooter } from "reactstrap";

const Table = ({ columns, keyField = 'id', data = [], title, sizePerPage = 10 }) => {

  const options = {
    custom: true,
    totalSize: data.length,
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
              value={currSizePerPage}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  };

  return (
    <>
      <PaginationProvider pagination={ paginationFactory(options) }>
      {
        ({
          paginationProps,
          paginationTableProps
        }) => (
          <div className="col-12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-12 col-lg-6 pb-2 pb-lg-0">
                  <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                    <h3 className="mb-0">{title}</h3>
                  </div>
                  <div className="col text-right">
                    {/* <Button color="primary" onClick={toggleAdd} size="sm">Add</Button> */}
                  </div>
                </Row>
              </CardHeader>
              
                <div className="table-responsive">
                  <BootstrapTable
                    keyField={keyField}
                    bootstrap4={true}
                    bordered={false}
                    headerClasses="thead-light"
                    columns={columns}
                    data={data}
                    { ...paginationTableProps }
                    noDataIndication="No records to display"
                    classes="align-items-center table-flush"
                  />
                </div>
                <CardFooter className="py-4">
                  <Row>
                    <Col lg={{size: 6, order: 12}}>
                      <PaginationListStandalone { ...paginationProps } />
                    </Col>
                    <Col lg={{size: 6, order: 1}}>
                      <SizePerPageDropdownStandalone { ...paginationProps } />
                      <PaginationTotalStandalone { ...paginationProps } />
                    </Col>
                  </Row>
                </CardFooter>
            </Card>
          </div>
        )}
      </PaginationProvider>
    </>
  );
}

export default Table;
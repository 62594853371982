import React from "react";
import { Row, Col } from "reactstrap";

import { playready, widevine, fairplay, total } from './CardsStats.module.scss';
import CardStat from './CardStat';
import { calculateMonthlyShareValue } from '../../utils/charts';

const CardsStats = ({ 
  issuedLicenses
}) => {
  return (
    <Col xl="12">
      <Row>
        <Col lg="6" xl="3">
          <CardStat 
            title="Playready" 
            value={issuedLicenses?.playready}
            monthlyShareValue={calculateMonthlyShareValue(issuedLicenses?.playready, issuedLicenses?.total)}
            color={playready} 
            icon="fab fa-microsoft" 
            difference={issuedLicenses?.changePlayready} 
            className="mb-4 mb-xl-0" />
        </Col>
        <Col lg="6" xl="3">
          <CardStat 
            title="Widevine" 
            value={issuedLicenses?.widevine}
            monthlyShareValue={calculateMonthlyShareValue(issuedLicenses?.widevine, issuedLicenses?.total)}
            color={widevine} 
            icon="fab fa-google" 
            difference={issuedLicenses?.changeWidevine} 
            className="mb-4 mb-xl-0" />
        </Col>
        <Col lg="6" xl="3">
          <CardStat 
            title="Fairplay" 
            value={issuedLicenses?.fairplay}
            monthlyShareValue={calculateMonthlyShareValue(issuedLicenses?.fairplay, issuedLicenses?.total)}
            color={fairplay} 
            icon="fab fa-apple" 
            difference={issuedLicenses?.changeFairplay} 
            className="mb-lg-0 mb-4 mb-xl-0" />
        </Col>
        <Col lg="6" xl="3">
          <CardStat 
            title="Total" 
            value={issuedLicenses?.total}
            monthlyShareValue={calculateMonthlyShareValue(issuedLicenses?.total, issuedLicenses?.total)}
            color={total} 
            icon="fas fa-chart-bar" 
            difference={issuedLicenses?.changeTotal} 
            className="mb-lg-0 mb-0 mb-xl-0" />
        </Col>
      </Row>
    </Col>
  );
}

export default CardsStats;

import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../components/Layout/Headers/BreadCrumb';
import { columns } from '../../../utils/tables/dashboards';
import Table from '../../../components/Common/Table';

const DashboardsPage = ({ isFetched, dashboards, onFetchDRMDashboards, match: { url } }) => {

  useEffect(() => {
    if (!isFetched) { onFetchDRMDashboards(); }
  }, [onFetchDRMDashboards, isFetched])

  return (
    <React.Fragment>
      <BreadCrumb url={url} />
      <Container fluid className="mt--13">
        <Row>
          <Table columns={columns} data={dashboards ?? []} keyField='id' title="DRM Dashboards" />
        </Row>
        <hr className="my-4 border-0" />
      </Container>
    </React.Fragment>
  )
}

export default DashboardsPage;
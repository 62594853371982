export const nFormatter = (num, digits) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const spaceInsert = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const extractEmails = ( text ) => text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi) ?? [];

export const highlightEmails = (text, color = '#000') => {
  const emails = extractEmails(text) ?? [];

  if (emails.length > 0) {
    return emails.map(email => {
      text = text.replace(email, `<span style="color: ${color};">${email}</span>`);
      return text;
    });
  } else {
    return text;
  }
}
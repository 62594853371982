import { 
  FETCH_DRM_DASHBOARD, 
  FETCH_DRM_DASHBOARD_FAIL,
  FETCH_DRM_DASHBOARD_START,
  FETCH_DRM_DASHBOARD_SUCCESS,
  FETCH_DRM_DASHBOARDS, 
  FETCH_DRM_DASHBOARDS_FAIL,
  FETCH_DRM_DASHBOARDS_START,
  FETCH_DRM_DASHBOARDS_SUCCESS,
} from './constants';

//FETCH_DRM_DASHBOARD, 
export const fetchDRMDashboard = (dashboardId) => ({
  type: FETCH_DRM_DASHBOARD,
  payload: { 
    dashboardId
  }
});

export const fetchDRMDashboardStart = () => ({
  type: FETCH_DRM_DASHBOARD_START
})

export const fetchDRMDashboardSuccess = (dashboard) => ({
  type: FETCH_DRM_DASHBOARD_SUCCESS,
  payload: dashboard
})

export const fetchDRMDashboardFail = (error) => ({
  type: FETCH_DRM_DASHBOARD_FAIL,
  payload: error
})

//FETCH_DRM_DASHBOARDS, 
export const fetchDRMDashboards = () => ({
  type: FETCH_DRM_DASHBOARDS
});

export const fetchDRMDashboardsStart = () => ({
  type: FETCH_DRM_DASHBOARDS_START
})

export const fetchDRMDashboardsSuccess = (dashboards) => ({
  type: FETCH_DRM_DASHBOARDS_SUCCESS,
  payload: dashboards
})

export const fetchDRMDashboardsFail = (error) => ({
  type: FETCH_DRM_DASHBOARDS_FAIL,
  payload: error
})

export const getCurrentDayNumber = () => new Date().getUTCDate();

export const getNumberOfDaysInCurrentMonth = () => {
	const date = new Date();
	return getNumberOfDaysInMonth(date.getMonth() + 1, date.getFullYear())
}

export const getNumberOfDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const calculateMonthProgress = () => {
  const numberOfDays = getNumberOfDaysInCurrentMonth();
  const currentDay = getCurrentDayNumber();

  return Math.round((100 * currentDay) / numberOfDays);
}

export const getYear = () => new Date().getFullYear();
import { connect } from 'react-redux';

import { selectIsDRMDashboardsFetched, fetchDRMDashboards } from '../../../modules/DRM/Dashboards';
import { selectAllDashboards } from '../../../modules/DRM/Dashboards';

const mapStateToProps = state => {
  return {
    isFetched: selectIsDRMDashboardsFetched(state),
    dashboards: selectAllDashboards(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDRMDashboards: () => dispatch(fetchDRMDashboards())
  }
}

export default connect(mapStateToProps, mapDispatchToProps);
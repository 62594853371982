import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  saveFairplaySystemConfigStart, 
  saveFairplaySystemConfigSuccess,
  saveFairplaySystemConfigFaill   
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* saveFairplaySystemConfigSaga({ payload: { configurationId, formData } }) {
  yield put(saveFairplaySystemConfigStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(saveFairplaySystemConfigSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(saveFairplaySystemConfigFaill());
    yield put(showErrorModal());
  }
}
import cloudMultiDRM from '../../../assets/icons/Cloud_Multi_DRM-white.png';
import processing from '../../../assets/icons/processing-white_tets.png';
import questions from '../../../assets/icons/questions-icon.png'

const en = {
  header: {
    title: "Cloud Multi DRM ",
    subtitle: "Thank you for choosing Insys Video Technologies!"
  },
  descriptions: [
    {
      img: {
        src: cloudMultiDRM,
        width: 45,
        height: 25
      },
      header: "Cloud Multi DRM",
      body: "Cloud Multi DRM is a fully cloud-native digital rights management licensing server for video streaming that enables the rapid adoption of well-recognized DRM systems."
    },
    {
      img: {
        src: processing,
        width: 45,
        height: 47
      },
      header: "Processing",
      body: "Your order has been received and now is being processed. Please fill out the form below to complete the order. We will contact you with the next steps in 2-3 business days."
    },
    {
      img: {
        src: questions,
        width: 45,
        height: 42
      },
      header: "Questions",
      body: "Should you have any questions, please feel free to contact us by email: marketplace@insysvt.com"
    }
  ],
  contanctForm: {
    checkboxAgreement: "I consent to have Insys Video Technologies sp. z o.o. sp. k. store my submitted information so they can respond to my inquiry and process the order."
  }
};

export { en };
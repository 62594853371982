import { createSelector } from 'reselect';

const selectConfigurationsIds = createSelector(
  state => state.drmConfigurations.configurationsIds,
  configurationsIds => configurationsIds
)

export const selectAllConfigurationsById = createSelector(
  state => state.drmConfigurations.configurationsById,
  configurationsById => configurationsById
)

export const selectIsDRMConfigurationsFetched = createSelector(
  state => state.drmConfigurations.isFetched,
  isFetched => isFetched
)

export const selectDRMConfigurations = createSelector(
  selectAllConfigurationsById,
  selectConfigurationsIds,
  (configurations, ids) => ids.map(id => {
    const configuration = configurations[id];
    return { ...configuration };
  })
)

export const makeGetConfigurationById = () => createSelector(
  (_, props) => props.configurationId,
  selectAllConfigurationsById,
  (configurationId, configurations) => configurations[configurationId]
)

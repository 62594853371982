import React from 'react';
import {
  Row,
  Card, CardHeader, CardBody,
} from 'reactstrap';
import Header from '../../../components/Marketplace/Header';
import StatusCard from '../../../components/Marketplace/StatusCard';
import { en } from './resources'; 

const DRMFulfilmentSuccessPage = ({ history }) => {
  const { header: { title, subtitle }, error: { body } } = en;

  const handleOnClick = (event) => {
    history.goBack();
  }

  return (
    <Card className="card-register card-plain">
      <CardHeader className="pb-0 border-bottom-0 text-center">
        <Row className="justify-content-center">
          <Header title={title} subtitle={subtitle} />
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <StatusCard status="error" body={body} btnText="TRY AGAIN" onClick={handleOnClick} />
        </Row>
      </CardBody>
    </Card>
  )
}

export default DRMFulfilmentSuccessPage;
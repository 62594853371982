import React, { useEffect, useState } from 'react';
import { AmplifySignIn, AmplifySignUp, AmplifyForgotPassword, AmplifyConfirmSignUp, AmplifyRequireNewPassword, AmplifyVerifyContact } from '@aws-amplify/ui-react'
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import FullPageBackground from '../Layout/Backgrounds/FullPageBackground';
import Background from '../../assets/images/bg-4.jpg';

const AmplifyAuth = () => {

  const [user, setUser] = useState({});

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setUser(authData);
    });
  }, []);

  const cleanSession = () => {
    return {
      ...user,
      Session: null
    }
  }
  
  return (
    <React.Fragment>
      <div className="App" slot="sign-in">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifySignIn headerText="Insys Cloud Services" hideSignUp />
      </div>

      <div className="App" slot="forgot-password">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifyForgotPassword headerText="Insys Cloud Services" />
      </div>
      
      <div className="App" slot="sign-up">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifySignUp 
          headerText="Insys Cloud Services"
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              required: true,
            },
            {
              type: "password",
              required: true,
            }
          ]} 
        />
      </div>

      <div className="App" slot="confirm-sign-up">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifyConfirmSignUp
          headerText="Sign Up - Insys Cloud Services"
          slot="confirm-sign-up"
        ></AmplifyConfirmSignUp>
      </div>

      <div className="App" slot="require-new-password">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifyRequireNewPassword headerText="Set New Password" user={user} />
      </div>

      <div className="App" slot="verify-contact">
        <FullPageBackground backgroundImage={Background} backgroundColor={'rgba(0,0,0,0.7)'} />
        <AmplifyVerifyContact headerText="Confirm E-mail address" user={cleanSession()} />
      </div>
    </React.Fragment>
  )
}

export default AmplifyAuth;
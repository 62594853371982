import { put, select } from 'redux-saga/effects';
import { saveDRMGeneralInfoStart, saveDRMGeneralInfoSuccess, saveDRMGeneralInfoFaill } from './actions'
// import axios from '../../api/axios';
import axios from '../../../api/axiosMockAdapter';
import { selectForSagaDRMConfigurationById, updateGeneralInfo } from '../../../modules/DRM/Core'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../Modal/actions';

export function* saveDRMGeneralInfoSaga({ payload: { configurationId, formData } }) {
  yield put(saveDRMGeneralInfoStart());
  try {

    const configuration = yield select(state => selectForSagaDRMConfigurationById()(state, { configurationId }));
    const payload = updateGeneralInfo(configuration, formData)
    console.log(payload);

    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(saveDRMGeneralInfoSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(saveDRMGeneralInfoFaill());
    yield put(showErrorModal());
  }
}
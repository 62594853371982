import { 
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION, 
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION, 
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION, 
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
} from './constants';

//Add
export const addDigitalAudioOutputProtection = (configurationId, formData) => ({
  type: ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const addDigitalAudioOutputProtectionStart = () => ({
  type: ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
});

export const addDigitalAudioOutputProtectionSuccess = (configurationId, formData) => ({
  type: ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const addDigitalAudioOutputProtectionFaill = () => ({
  type: ADD_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
});

//Edit
export const editDigitalAudioOutputProtection = (configurationId, formData) => ({
  type: EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const editDigitalAudioOutputProtectionStart = () => ({
  type: EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
});

export const editDigitalAudioOutputProtectionSuccess = (configurationId, formData) => ({
  type: EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const editDigitalAudioOutputProtectionFaill = () => ({
  type: EDIT_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
});

//Delete
export const deleteDigitalAudioOutputProtection = (configurationId, formData) => ({
  type: DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const deleteDigitalAudioOutputProtectionStart = () => ({
  type: DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_START,
});

export const deleteDigitalAudioOutputProtectionSuccess = (configurationId, formData) => ({
  type: DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const deleteDigitalAudioOutputProtectionFaill = () => ({
  type: DELETE_DIGITAL_VIDEO_OUTPUT_PROTECTION_FAIL,
});
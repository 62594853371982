import { 
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION, 
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION, 
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION, 
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
} from './constants';

//Add
export const addAnalogVideoOutputProtection = (configurationId, formData) => ({
  type: ADD_ANALOG_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const addAnalogVideoOutputProtectionStart = () => ({
  type: ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
});

export const addAnalogVideoOutputProtectionSuccess = (configurationId, formData) => ({
  type: ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const addAnalogVideoOutputProtectionFaill = () => ({
  type: ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
});

//Edit
export const editAnalogVideoOutputProtection = (configurationId, formData) => ({
  type: EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const editAnalogVideoOutputProtectionStart = () => ({
  type: EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
});

export const editAnalogVideoOutputProtectionSuccess = (configurationId, formData) => ({
  type: EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const editAnalogVideoOutputProtectionFaill = () => ({
  type: EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
});

//Delete
export const deleteAnalogVideoOutputProtection = (configurationId, formData) => ({
  type: DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION,
  payload: {
    configurationId,
    formData
  }
});

export const deleteAnalogVideoOutputProtectionStart = () => ({
  type: DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_START,
});

export const deleteAnalogVideoOutputProtectionSuccess = (configurationId, formData) => ({
  type: DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const deleteAnalogVideoOutputProtectionFaill = () => ({
  type: DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL,
});
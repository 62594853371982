import React from 'react';
import { fullPageBackground } from './FullPageBackground.module.scss';

const FullPageBackground = ({ backgroundImage, backgroundColor }) => (
  <div style={{
      backgroundImage: `url(${backgroundImage})`,
      '--background-color-var': backgroundColor
    }} 
    className={fullPageBackground}>
  </div>
)

export default FullPageBackground;
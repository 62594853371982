import { guiAxiosInstance as axios } from '../../../api/axios';
import { put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';

import { 
  fetchDRMConfigurationStart,
  fetchDRMConfigurationFail,
  fetchDRMConfigurationsStart,
  fetchDRMConfigurationsSuccess,
  fetchDRMConfigurationsFail 
} from './actions';

import {
  showProcessingModal,
  hideModal,
  showErrorModal
} from '../../Modal/actions';

//TODO: Verify this 
export function* fetchDRMConfigurationSaga() {
  yield put(fetchDRMConfigurationStart())
  try {
    yield put(showProcessingModal());
    // const response = yield axiosMockAdapter.get("/drm/6e6gf012-f418-4e78-862d-5710e1ac6216");
    
    // yield put(fetchDRMConfigurationSuccess(response.data));
    yield put(hideModal());

  } catch(error) {
    yield put(fetchDRMConfigurationFail());
    yield put(showErrorModal());
  }
}

export function* fetchDRMConfigurationsSaga() {
  yield put(fetchDRMConfigurationsStart())
  try {

    yield put(showProcessingModal());

    const access_token = yield Auth.currentSession().then(res=> { 
      let accessToken = res.getAccessToken()
      return accessToken.getJwtToken()
    })
    
    const response = yield axios.get('/drmmanagement/configurations', {
      headers: {
        Authorization: 'Bearer '+ access_token
      }
    });

    yield put(fetchDRMConfigurationsSuccess(response.data.drmSystems));
    yield put(hideModal());

  } catch(error) {
    yield put(fetchDRMConfigurationsFail());
    yield put(showErrorModal());
  }
}
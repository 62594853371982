import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  addDigitalAudioOutputProtectionStart, 
  addDigitalAudioOutputProtectionSuccess,
  addDigitalAudioOutputProtectionFaill,
  editDigitalAudioOutputProtectionStart, 
  editDigitalAudioOutputProtectionSuccess,
  editDigitalAudioOutputProtectionFaill,
  deleteDigitalAudioOutputProtectionStart, 
  deleteDigitalAudioOutputProtectionSuccess,
  deleteDigitalAudioOutputProtectionFaill  
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* addDigitalAudioOutputProtectionSaga({ payload: { configurationId, formData } }) {
  yield put(addDigitalAudioOutputProtectionStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(addDigitalAudioOutputProtectionSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error);
    console.log(error.response);
    yield put(addDigitalAudioOutputProtectionFaill());
    yield put(showErrorModal());
  }
}

export function* editDigitalAudioOutputProtectionSaga({ payload: { configurationId, formData } }) {
  yield put(editDigitalAudioOutputProtectionStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(editDigitalAudioOutputProtectionSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(editDigitalAudioOutputProtectionFaill());
    yield put(showErrorModal());
  }
}

export function* deleteDigitalAudioOutputProtectionSaga({ payload: { configurationId, formData } }) {
  yield put(deleteDigitalAudioOutputProtectionStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(deleteDigitalAudioOutputProtectionSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(deleteDigitalAudioOutputProtectionFaill());
    yield put(showErrorModal());
  }
}
import { guiAxiosInstance as axios } from '../../../api/axios';
import { put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { calculateRate as calculateRateLicenseSummary } from '../../../utils/tables/licensesSummary';
import { calculateRate as calculateRateLicensesPeaks } from '../../../utils/tables/licensesPeaks';

import { 
  fetchDRMDashboardStart,
  fetchDRMDashboardSuccess,
  fetchDRMDashboardFail,
  fetchDRMDashboardsStart,
  fetchDRMDashboardsSuccess,
  fetchDRMDashboardsFail
} from './actions';

import {
  showProcessingModal,
  hideModal,
  showErrorModal
} from '../../Modal/actions';

import { open } from '../../../features/alertModal/alertModalSlice';
import { en } from '../../../resources/tenantNotReady';

export function* fetchDRMDashboardSaga({ payload: { dashboardId } }) {
  
  yield put(fetchDRMDashboardStart())
  try {

    yield put(showProcessingModal());

    const access_token = yield Auth.currentSession().then(res=> { 
      let accessToken = res.getAccessToken()
      return accessToken.getJwtToken()
    })

    const response = yield axios.get(`/drmmanagement/dashboard?brandGuid=${dashboardId}`, {
      headers: {
        Authorization: 'Bearer '+ access_token
      }
    });

    if (response.data.dashboard.licensesSummary) {
      response.data.dashboard.licensesSummary = calculateRateLicenseSummary(response.data.dashboard.licensesSummary ?? []);
    }

    if (response.data.dashboard.licensesPeaks) {
      response.data.dashboard.licensesPeaks = calculateRateLicensesPeaks(response.data.dashboard.licensesPeaks.reverse() ?? []);
    }

    yield put(fetchDRMDashboardSuccess(response.data.dashboard));
    yield put(hideModal());

  } catch(error) {
    yield put(fetchDRMDashboardFail());
    yield put(showErrorModal());
  }
}

export function* fetchDRMDashboardsSaga() {
  yield put(fetchDRMDashboardsStart())
  try {

    yield put(showProcessingModal());

    const access_token = yield Auth.currentSession().then(res=> { 
      let accessToken = res.getAccessToken()
      return accessToken.getJwtToken()
    })
    
    const response = yield axios.get('/drmmanagement/dashboards', {
      headers: {
        Authorization: 'Bearer '+ access_token
      }
    });
    
    yield put(fetchDRMDashboardsSuccess(response.data.drmSystems));
    yield put(hideModal());

    if (response.data.drmSystems.filter(system => !system.isReady).length > 0) {
      yield put(open({ title: en.modal.title, body: en.modal.body }));
    }

  } catch(error) {
    yield put(fetchDRMDashboardsFail());
    yield put(showErrorModal());
  }
}

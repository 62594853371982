import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../components/Layout/Headers/BreadCrumb';
import LineChart from '../../../components/Charts/LineChart';
import CardsStats from '../../../components/Charts/CardsStats';
import Table from '../../../components/Common/Table';
import { columns as licensesSummaryColumns } from '../../../utils/tables/licensesSummary';
import { columns as licensesPeaksColumns } from '../../../utils/tables/licensesPeaks';

const DashboardPage = ({ 
  isFetched, 
  dashboardsIds, 
  dashboard, 
  onFetchDRMDashboards, 
  onFetchDRMDashboard, 
  match
}) => {

  const { url, params: { dashboardId } } = match;
  const [isWrongRuleGuid, setIsWrongRuleGuid] = useState(false);

  useEffect(() => {
    if (isFetched) { 
      if(!(dashboardsIds && dashboardsIds.some(item => item === dashboardId))){
        setIsWrongRuleGuid(true);
      } else {
        onFetchDRMDashboard(dashboardId)
      }
    } else {
      onFetchDRMDashboards();
    }
  }, [onFetchDRMDashboard, onFetchDRMDashboards, isFetched, dashboardsIds, dashboardId])
  
  return (
    <React.Fragment>
      <BreadCrumb url={url} />
      <Container fluid className="mt--13">
        { isWrongRuleGuid ? <Redirect to="/" /> : null }
        <Row>
          <CardsStats issuedLicenses={dashboard?.issuedLicenses ?? null} />
        </Row>
        <hr className="my-4 border-0" />
        <Row>
          <LineChart title="ISSUED LICENCES" darkMode={false} data={dashboard?.correctRequests ?? null} />
        </Row>
        <hr className="my-4 border-0" />
        <Row>
          <LineChart title="ALL LICENCE REQUESTS" darkMode={false} data={dashboard?.allRequests ?? null} />
        </Row>
        <hr className="my-4 border-0" />
        <Row>
          <LineChart title="BAD LICENCE REQUESTS" darkMode={false} data={dashboard?.badRequests ?? null} />
        </Row>
        <hr className="my-4 border-0" />
        <Row>
          <Table columns={licensesSummaryColumns} data={dashboard?.licensesSummary ?? []} keyField='month' title="LICENSES SUMMARY" />
        </Row>
        <hr className="my-4 border-0" />
        <Row>
          <Table columns={licensesPeaksColumns} data={dashboard?.licensesPeaks ?? []} keyField='date' title="ISSUED LICENSES PEAKS PER SECOND" sizePerPage={5} />
        </Row>
        <hr className="my-4 border-0" />
      </Container>
    </React.Fragment>
  )
}

export default DashboardPage;

// Test
// export default {
//   "aws_project_region": "eu-west-1",
//   "aws_cognito_identity_pool_id": "eu-west-1:efed1412-5771-43f8-b986-bd671d248669",
//   "aws_cognito_region": "eu-west-1",
//   "aws_user_pools_id": "eu-west-1_4gOpFoPOe",
//   "aws_user_pools_web_client_id": "2sn8m737bvm5ibe1c50n7jrsin"
// }

// COT
// export default {
//   "aws_cognito_region": "eu-west-1",
//   "aws_user_pools_id": "eu-west-1_BojGLIlUi",
//   "aws_user_pools_web_client_id": "c33aj03f0qkv92rkjil4mjk5k"
// }

//PROD
export default {
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_SdrBVKdt3",
  "aws_user_pools_web_client_id": "3bseb1km14p8rhmfasb5ep3ba6"
}
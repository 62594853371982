import { takeEvery, all } from 'redux-saga/effects';

import {
  FETCH_DRM_DASHBOARD,
  FETCH_DRM_DASHBOARDS,
} from './constants';

import {
  fetchDRMDashboardSaga,
  fetchDRMDashboardsSaga
} from './saga';

export function* watchDRMDashboards() {
  yield all([
    takeEvery(FETCH_DRM_DASHBOARD, fetchDRMDashboardSaga),
    takeEvery(FETCH_DRM_DASHBOARDS, fetchDRMDashboardsSaga),
  ])
}
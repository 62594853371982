import axios from 'axios';

const guiAxiosInstance = axios.create({
  baseURL: 'https://owhb7qwr4d.execute-api.eu-west-1.amazonaws.com/v1/'
});

const marketplaceAxiosInstance = axios.create({
  baseURL: 'https://api.marketplace.cloud.insysvt.com/api/'
});

export {
  guiAxiosInstance,
  marketplaceAxiosInstance
};
import { connect } from 'react-redux';

import { 
  selectIsDRMDashboardsFetched, 
  selectDashboardsIds,
  fetchDRMDashboards,
  fetchDRMDashboard,
  makeGetDashboardById
} from '../../../modules/DRM/Dashboards';

const mapStateToProps = (state, { match: { params: { dashboardId } } }) => {
  const selectDashboardById = makeGetDashboardById();
  const ownProps = { dashboardId };
  return (state) => {
    return {
      isFetched: selectIsDRMDashboardsFetched(state),
      dashboardsIds: selectDashboardsIds(state),
      dashboard: selectDashboardById(state, ownProps)
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDRMDashboards: () => dispatch(fetchDRMDashboards()),
    onFetchDRMDashboard: (dashboardId) => dispatch(fetchDRMDashboard(dashboardId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps);
import { DashboardsPage } from '../../pages/DRM/DashboardsPage';
import { DashboardPage } from '../../pages/DRM/DashboardPage';

const routes = [
  {
    path: "/drm/dashboards",
    name: "Dashboards",
    component: DashboardsPage,
    layout: "/admin"
  },
  {
    path: "/drm/dashboards/:dashboardId",
    name: "DRM",
    component: DashboardPage,
    layout: "/admin",
  }
];
export default routes;
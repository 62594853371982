import { 
  FETCH_DRM_CONFIGURATION, 
  FETCH_DRM_CONFIGURATION_FAIL,
  FETCH_DRM_CONFIGURATION_START,
  FETCH_DRM_CONFIGURATION_SUCCESS,
  FETCH_DRM_CONFIGURATIONS, 
  FETCH_DRM_CONFIGURATIONS_FAIL,
  FETCH_DRM_CONFIGURATIONS_START,
  FETCH_DRM_CONFIGURATIONS_SUCCESS
} from './constants';

//FETCH_DRM_CONFIGURATION
export const fetchDRMConfiguration = () => ({
  type: FETCH_DRM_CONFIGURATION
});

export const fetchDRMConfigurationStart = () => ({
  type: FETCH_DRM_CONFIGURATION_START
})

export const fetchDRMConfigurationSuccess = (configuration) => ({
  type: FETCH_DRM_CONFIGURATION_SUCCESS,
  payload: configuration
})

export const fetchDRMConfigurationFail = (error) => ({
  type: FETCH_DRM_CONFIGURATION_FAIL,
  payload: error
})

//FETCH_DRM_CONFIGURATIONS
export const fetchDRMConfigurations = () => ({
  type: FETCH_DRM_CONFIGURATIONS
});

export const fetchDRMConfigurationsStart = () => ({
  type: FETCH_DRM_CONFIGURATIONS_START
})

export const fetchDRMConfigurationsSuccess = (configurations) => ({
  type: FETCH_DRM_CONFIGURATIONS_SUCCESS,
  payload: configurations
})

export const fetchDRMConfigurationsFail = (error) => ({
  type: FETCH_DRM_CONFIGURATIONS_FAIL,
  payload: error
})
import React from 'react';
import More from '../../components/Common/More';
import StatusBadge from '../../components/Common/StatusBadge';

export const columns = [
  {
    dataField: "id",
    text: "ID"
  },
  {
    dataField: "brandGuid",
    text: "BRANDGUID"
  },
  {
    dataField: "name",
    text: "NAME",
  },
  {
    dataField: "isReady",
    text: "STATUS",
    formatter: (cell, row) => {
      return (
        <React.Fragment>
          { 
            cell ? (
              <StatusBadge statusClassName="bg-success" text="Ready" />
            ) : (
              <StatusBadge statusClassName="bg-warning" text="Not ready" />
            )
          }
        </React.Fragment>
      );
    }
  },
  {
    dataField: "",
    text: '',
    classes: 'text-right',
    formatter: (cell, { id, isReady }) => <More id={id} isReady={isReady} />
  }
];
import React from 'react';
import classnames from 'classnames';
import { spaceInsert } from '../formatter';

export const columns = [
  {
    dataField: "month",
    text: "MONTH"
  },
  {
    dataField: "playready",
    text: "PLAYREADY",
    formatter: (cell, row) => spaceInsert(cell)
  },
  {
    dataField: "widevine",
    text: "WIDEVINE",
    formatter: (cell, row) => spaceInsert(cell)
  },
  {
    dataField: "fairplay",
    text: "FAIRPLAY",
    formatter: (cell, row) => spaceInsert(cell)
  },
  {
    dataField: "total",
    text: "TOTAL",
    formatter: (cell, row) => spaceInsert(cell)
  },
  {
    dataField: "rate",
    text: 'INCREASE RATE',
    formatter: (cell, row) => {
      return (
        <span className="mr-2">
          <i className={classnames("fa", {
              "fa-arrow-down text-warning": cell < 0,
              "fa-arrow-up text-success": cell > 0,
            })} 
          /> { cell ? `${cell} ${cell <= 0 || cell > 0 ? '%' : '' }` : '-' }
        </span>
      )
    }
  }
];

export const calculateRate = (items) => {
	if (items && items.length > 1) {
		for (let i = 0; i < items.length; i++) {
      if (i + 1 === items.length) {
				items[i].rate = '-';
			} else {
				items[i].rate = (((items[i].total - items[i+1].total) * 100) / items[i+1].total).toFixed(2);
			}
		}
	}
	return items;
}
import { DRMFulfilmentPage } from '../../pages/Marketplace/DRMFulfilmentPage';
import { DRMFulfilmentSuccessPage } from '../../pages/Marketplace/DRMFulfilmentSuccessPage';
import { DRMFulfilmentErrorPage } from '../../pages/Marketplace/DRMFulfilmentErrorPage';

const routes = [
  {
    path: "/drm",
    name: "DRM Fulfilment Page",
    component: DRMFulfilmentPage,
    layout: "/marketplace",
  },
  {
    path: "/drm/success",
    name: "DRM Fulfilment Success Page",
    component: DRMFulfilmentSuccessPage,
    layout: "/marketplace",
  },
  {
    path: "/drm/error",
    name: "DRM Fulfilment Error Page",
    component: DRMFulfilmentErrorPage,
    layout: "/marketplace",
  }
];

export default routes;
import { spaceInsert } from './formatter';
import moment from 'moment';
export const createLineChartDatasets = (data, scale) => {

	let result = {
		options: null,
		datasets: null
	};
	
	if (data) {
		const { day, week, month } = data;

		result = {
			options: {
				scales: {
					yAxes: [
						{
							type: 'linear',
							display: true,
							position: 'left',
							id: 'y-axis-1',
							gridLines: {
								color: "#e9ecef",
								zeroLineColor: "#e9ecef"
							},
							ticks: {}
						}
					],
					xAxes: [
						{
							id: 'x-axis-1',
							ticks: {
									autoSkip: true,
									autoSkipPadding: 30,
									maxRotation: 45,
									minRotation: 45,
								}
						}
					]
				},
				tooltips: {
					callbacks: {
						label: function(item, data) {
							var label = data.datasets[item.datasetIndex].label || "";
							var yLabel = item.yLabel;
							var content = "";
		
							if (data.datasets.length > 1) {
								content += label;
							}
		
							content += ": " + spaceInsert(yLabel) + "";
							return content;
						}
					}
				}
			},
			datasets: [
				createDataset(day.labels, day.playready, day.widevine, day.fairplay, day.total, "Day", null),
				createDataset(week.labels, week.playready, week.widevine, week.fairplay, week.total, "Week", scale),
				createDataset(month.labels, month.playready, month.widevine, month.fairplay, month.total, "Month", scale),
			]
		}
	} 

	return result;
}

export const calculateMonthlyShareValue = (value = 0, totalValue = 1) => {
  return (value / totalValue).toFixed(2) * 100;
}

const createDataset = (labels, playready, widevine, fairplay, total, name, scale) => {
  labels = fixLabelToLocaltime(labels);
	const result = aggregateByDays(labels, playready, widevine, fairplay, total, scale);

	labels = result.labels;
	playready = result.playready;
	widevine = result.widevine;
	fairplay = result.fairplay;
	total = result.total;

	return {
		name,
		data: canvas => {
			return {
				labels,
				datasets: [
					{
						label: 'Playready',
						data: playready,
						fill: false,
						backgroundColor: 'rgb(255, 99, 132)',
						borderColor: 'rgba(255, 99, 132, 0.2)',
						yAxisID: 'y-axis-1',
						xAxisID: 'x-axis-1',
						lineTension: 0
					},
					{
						label: 'Widevine',
						data: widevine,
						fill: false,
						backgroundColor: 'rgb(54, 162, 235)',
						borderColor: 'rgba(54, 162, 235, 0.2)',
						yAxisID: 'y-axis-1',
						xAxisID: 'x-axis-1',
						lineTension: 0
					},
					{
						label: 'Fairplay',
						data: fairplay,
						fill: false,
						backgroundColor: 'rgb(54, 162, 0)',
						borderColor: 'rgba(54, 162, 0, 0.2)',
						yAxisID: 'y-axis-1',
						xAxisID: 'x-axis-1',
						lineTension: 0
					},
					{
						label: 'Total',
						data: total,
						fill: false,
						backgroundColor: 'rgb(255, 162, 50)',
						borderColor: 'rgba(255, 162, 50, 0.2)',
						yAxisID: 'y-axis-1',
						xAxisID: 'x-axis-1',
						lineTension: 0
					}
				]
			}
		}
	}	
}

const aggregateByDays = (labels, playready, widevine, fairplay, total, scale) => {
	if (scale === 'days') {
		const result = {
			labels: [],
			playready: [],
			widevine: [],
			fairplay: [],
			total: []
		};
		const aggregation = {};
		for (let i = 0; i < labels.length; i++) {
			let date = labels[i].split(' ')[0];
			if (aggregation[date]) {
				aggregation[date].playready += playready[i];
				aggregation[date].widevine += widevine[i];
				aggregation[date].fairplay += fairplay[i];
				aggregation[date].total += total[i];
			} else {
				aggregation[date] = {
					playready: playready[i],
					widevine: widevine[i],
					fairplay: fairplay[i],
					total: total[i]
				}
			}
		}
		
		for (const item in aggregation) {
			result.labels.push(item);
			result.playready.push(aggregation[item].playready)
			result.widevine.push(aggregation[item].widevine)
			result.fairplay.push(aggregation[item].fairplay)
			result.total.push(aggregation[item].total)
		}

		return result;
	} else {
		return {
			labels,
			playready,
			widevine,
			fairplay,
			total
		}
	}
}

const fixLabelToLocaltime = (labels) => {

  const fixedLabels = [];

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const properFormatDate = `${new Date().getFullYear()}/${label} +0000`;
    const fiexdLabel = moment(properFormatDate).local().format('DD/MM HH:mm');

    fixedLabels.push(fiexdLabel);
  }

  return fixedLabels; 
}
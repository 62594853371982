import React from 'react';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import { invalid } from './AgreeCheckbox.module.scss';

const AgreeCheckbox = ({ text, value, handleChange, htmlFor, errors }) => {
  return (
    <FormGroup className={classnames("mb-0", errors ? invalid : null)}>
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id={htmlFor}
          type="checkbox"
          checked={value}
          onChange={handleChange}
        />
        <label
          className="custom-control-label"
          htmlFor={htmlFor}
        >
          <span className="text-muted">{text}</span>
        </label>
          { errors ? <div className="invalid-feedback">{errors}</div> : null }
      </div>
    </FormGroup>
  )
};

export default AgreeCheckbox;
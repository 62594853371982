import { combineReducers } from '@reduxjs/toolkit';
import drmConfigurations from '../modules/DRM/Core/reducer';
import drmDashboards from '../modules/DRM/Dashboards/reducer';
import modal from '../modules/Modal/reducer';

import alertModalReducer from '../features/alertModal/alertModalSlice';

export default combineReducers({
  drmConfigurations,
  drmDashboards,
  modal,
  alertModal: alertModalReducer
}); 

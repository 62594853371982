import React, { useState } from 'react';
import classnames from 'classnames';
import { InputGroup as InputGroupBootstrap, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { invalid, active } from './InputGroup.module.scss';

const InputGroup = ({ placeholder, icon, htmlFor, value, handleChange, handleBlur, errors }) => {

  const [isFocus, setIsFocus] = useState(false);

  const handleOnFocus = () => {
    setIsFocus(true);
  }

  const handleonFocusOut = (e) => {
    setIsFocus(false);
    handleBlur(e);
  }

  return (
    <div className={classnames("pb-3", 
      errors ? invalid : null, 
      isFocus ? active: null)
    }>
      <InputGroupBootstrap className="">
        <InputGroupAddon addonType="prepend">
          <InputGroupText><i className={classnames(icon)}></i></InputGroupText>
        </InputGroupAddon>
        <Input 
          id={htmlFor} 
          placeholder={placeholder} 
          value={value} 
          onChange={handleChange} 
          onBlur={handleonFocusOut} 
          className={errors ? "is-invalid" : null}
          onFocus={handleOnFocus}
        />
      </InputGroupBootstrap>
      { errors ? <div className="invalid-feedback">{errors}</div> : null }
    </div>
  );
}

export default InputGroup;
    
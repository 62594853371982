import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'alertModal',
  initialState: {
    open: false,
    title: '',
    body: '',
  },
  reducers: {
    open: (state, action) => {
      state.open = true;
      state.title = action.payload?.title ?? 'You should read this!';
      state.body = action.payload?.body ?? '';
    },
    close: state => {
      state.open = false;
      state.title = '';
      state.body = '';
    }
  }
});

export const { open, close } = slice.actions;

export const selectOpen = state => state.alertModal.open;
export const selectTitle = state => state.alertModal.title;
export const selectBody = state => state.alertModal.body;

export default slice.reducer;
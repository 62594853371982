import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  saveWidevineSystemConfigStart, 
  saveWidevineSystemConfigSuccess,
  saveWidevineSystemConfigFaill   
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* saveWidevineSystemConfigSaga({ payload: { configurationId, formData } }) {
  yield put(saveWidevineSystemConfigStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(saveWidevineSystemConfigSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(saveWidevineSystemConfigFaill());
    yield put(showErrorModal());
  }
}
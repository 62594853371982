const en = {
  header: {
    title: "Cloud Multi DRM ",
    subtitle: "Thank you for choosing Insys Video Technologies!"
  },
  success: {
    body: "Thank you for filling the form. Your account has been successfully created. We have sent further instructions to your email address. Take a look at your SPAM folders if you cannot find the activation email in your inbox. If you have any questions, contact us by email: marketplace@insysvt.com"
  }
};

export { en };
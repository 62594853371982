import { 
  SAVE_DRM_GENERAL_INFO, 
  SAVE_DRM_GENERAL_INFO_START,
  SAVE_DRM_GENERAL_INFO_SUCCESS,
  SAVE_DRM_GENERAL_INFO_FAIL
} from './constants';

export const saveDRMGeneralInfo = (configurationId, formData) => ({
  type: SAVE_DRM_GENERAL_INFO,
  payload: {
    configurationId,
    formData
  }
});

export const saveDRMGeneralInfoStart = () => ({
  type: SAVE_DRM_GENERAL_INFO_START,
});

export const saveDRMGeneralInfoSuccess = (configurationId, formData) => ({
  type: SAVE_DRM_GENERAL_INFO_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveDRMGeneralInfoFaill = () => ({
  type: SAVE_DRM_GENERAL_INFO_FAIL,
});
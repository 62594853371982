import React from 'react';
import { Col } from 'reactstrap';
import Media from '../Common/Media';

const Descriptions = ({ descriptions }) => {
  return (
    <Col lg="6" md="7" className="ml-auto">
      {
        descriptions 
          ? descriptions.map(({ header, body, icon, img }, key) => (
              <Media
                key={key}
                img={img}
                icon={icon}
                header={header} 
                body={body} />
            )) 
          : null
      }
    </Col>
  )
};

export default Descriptions;

import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  savePlayreadySystemConfigStart, 
  savePlayreadySystemConfigSuccess,
  savePlayreadySystemConfigFaill   
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* savePlayreadySystemConfigSaga({ payload: { configurationId, formData } }) {
  yield put(savePlayreadySystemConfigStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(savePlayreadySystemConfigSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(savePlayreadySystemConfigFaill());
    yield put(showErrorModal());
  }
}
import { DashboardsPage } from '../../pages/DRM/DashboardsPage';

var routes = [
  {
    collapse: true,
    name: "DRM",
    icon: "ni ni-lock-circle-open text-orange",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/drm/dashboards",
        name: "Dashboards",
        miniName: "",
        component: DashboardsPage,
        layout: "/admin"
      }
    ]
  }
];
export default routes;
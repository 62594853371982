import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactBSAlert from "react-bootstrap-sweetalert";

import { hideModal } from '../../modules/Modal/actions';
import { ModalStates } from '../../constants/ModalStates';
import loader from '../../assets/images/2.svg';

const Modal = ({ modalState, onHideModal }) => {

  const [modal, setModal] = useState(null);

  useEffect(() => {
    switch (modalState) {
      case ModalStates.Hide:
        setModal(null);
        break;
      case ModalStates.Error:
        setModal(<ReactBSAlert
          warning
          title="Something went wrong"
          onConfirm={() => onHideModal()}
          onCancel={() => onHideModal()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
          // timeout={3000}
        >
        </ReactBSAlert>)
        break;
      case ModalStates.Processing:
        setModal(<ReactBSAlert
          custom
          title="Processing..."
          onConfirm={() => {}}
          onCancel={() => {}}
          customIcon={loader}
          customButtons={
            <React.Fragment />
          }
        />);
        break;
      case ModalStates.Success:
        setModal(<ReactBSAlert 
          success 
          title="Operation successful" 
          onConfirm={() => onHideModal()} 
          onCancel={() => onHideModal()}
          timeout={2000}
          >
            {/* You clicked the button! */}
        </ReactBSAlert>)
        break;
      default:
        setModal(null);
        break;
    }
  },[modalState, onHideModal])

  return (
    <div>{modal}</div>
  ) 
}

const mapStateToProps = state => ({
  modalState: state.modal.modalState
})

const mapDispatchToProps = dispatch => ({
  onHideModal: () => dispatch(hideModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
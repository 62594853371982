import { 
  ADD_PLAYER_ENABLER, 
  ADD_PLAYER_ENABLER_START,
  ADD_PLAYER_ENABLER_SUCCESS,
  ADD_PLAYER_ENABLER_FAIL,
  DELETE_PLAYER_ENABLER, 
  DELETE_PLAYER_ENABLER_START,
  DELETE_PLAYER_ENABLER_SUCCESS,
  DELETE_PLAYER_ENABLER_FAIL,
} from './constants';

//Add
export const addPlayerEnabler = (configurationId, formData) => ({
  type: ADD_PLAYER_ENABLER,
  payload: {
    configurationId,
    formData
  }
});

export const addPlayerEnablerStart = () => ({
  type: ADD_PLAYER_ENABLER_START,
});

export const addPlayerEnablerSuccess = (configurationId, formData) => ({
  type: ADD_PLAYER_ENABLER_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const addPlayerEnablerFaill = () => ({
  type: ADD_PLAYER_ENABLER_FAIL,
});

//Delete
export const deletePlayerEnabler = (configurationId, formData) => ({
  type: DELETE_PLAYER_ENABLER,
  payload: {
    configurationId,
    formData
  }
});

export const deletePlayerEnablerStart = () => ({
  type: DELETE_PLAYER_ENABLER_START,
});

export const deletePlayerEnablerSuccess = (configurationId, formData) => ({
  type: DELETE_PLAYER_ENABLER_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const deletePlayerEnablerFaill = () => ({
  type: DELETE_PLAYER_ENABLER_FAIL,
});
import { put } from 'redux-saga/effects';
import { addLicenseRuleStart, addLicenseRuleSuccess, addLicenseRuleFaill } from './actions'
// import axios from '../../api/axios';
// import axios from '../../../api/axiosMockAdapter';
// import { selectForSagaDRMConfigurationById, updateGeneralInfo } from '../../../modules/DRM/Core'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* addLicenseRuleSaga({ payload: { configurationId, formData } }) {
  console.log(configurationId);
  console.log(formData);
  yield put(addLicenseRuleStart());
  try {

    // const configuration = yield select(state => selectForSagaDRMConfigurationById()(state, { configurationId }));
    // const payload = updateGeneralInfo(configuration, formData)
    // console.log(payload);

    yield put(showProcessingModal());
    // const response = yield axios.get("/users");
    yield put(addLicenseRuleSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(addLicenseRuleFaill());
    yield put(showErrorModal());
  }
}
const en = {
  header: {
    title: "Cloud Multi DRM ",
    subtitle: "Thank you for choosing Insys Video Technologies!"
  },
  error: {
    body: "Something wired happened. Please try again or contact us by email: marketplace@insysvt.com"
  }
};

export { en };
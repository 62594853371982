import React from "react";
import classnames from "classnames";
import { Card, CardBody, CardTitle, Row, Col, Progress, UncontrolledTooltip } from "reactstrap";
import { makeId } from '../../utils/general';
import { nFormatter } from '../../utils/formatter';

const CardStat = ({ title, value, monthlyShareValue, icon, color, difference, className }) => {

  const progressId = makeId(10);
  monthlyShareValue = monthlyShareValue || 0;

  return (
    <Card className={classnames("card-stats", className)} >
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">
              { value ? nFormatter(value, 1) : 0 }
            </span>
          </div>
          <Col className="col-auto pl-0">
            <div className={ classnames("icon-shape text-white rounded-circle shadow", color) }>
              <i className={ classnames(icon) } />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12 mt-2 mb-2" style={{display: 'flex'}}>
            <span className="mr-2">{monthlyShareValue}%</span>
            <div style={{width: "100%", height: 8, paddingTop: 8}}>
              <Progress
                max="100"
                value={monthlyShareValue}
                barClassName={color}
                id={progressId}
              ></Progress>
            </div>
          </Col>
        </Row>
        <p className="mb-0 text-muted text-sm">
          <span className="mr-2">
            <i className={classnames("fa", {
                "fa-arrow-down text-warning": difference < 0,
                "fa-arrow-up text-success": difference > 0,
              })} 
            /> { difference ?? 0 }%
          </span>{" "}
          <span className="text-nowrap">Since last month</span>
        </p>
      </CardBody>
      {/* <CardFooter className="text-muted ml-4 mr-4 pt-2 pb-2 pl-0 pr-0 small">
        <small>
        <i className="fas fa-sync-alt"></i> Updated 5 min ago
        </small>
      </CardFooter> */}
      <UncontrolledTooltip placement="bottom" target={progressId}>
        Current month progress
      </UncontrolledTooltip>
    </Card>
  )
}

export default CardStat;
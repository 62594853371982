export const ADD_ANALOG_VIDEO_OUTPUT_PROTECTION = 'ADD_ANALOG_VIDEO_OUTPUT_PROTECTION';
export const ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_START = 'ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_START';
export const ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL = 'ADD_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL';

export const EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION = 'EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION';
export const EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_START = 'EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_START';
export const EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL = 'EDIT_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL';

export const DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION = 'DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION';
export const DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_START = 'DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_START';
export const DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS = 'DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_SUCCESS';
export const DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL = 'DELETE_ANALOG_VIDEO_OUTPUT_PROTECTION_FAIL';
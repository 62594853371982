import {
  HIDE_MODAL,
  SHOW_ERROR_MODAL,
  SHOW_PROCESSING_MODAL,
  SHOW_SUCCESS_MODAL
} from './constants';

export const hideModal = () => ({
  type: HIDE_MODAL
});

export const showProcessingModal = () => ({
  type: SHOW_PROCESSING_MODAL
});

export const showErrorModal = () => ({
  type: SHOW_ERROR_MODAL
});

export const showSuccessModal = () => ({
  type: SHOW_SUCCESS_MODAL
});
import { 
  SAVE_PLAYREADY_LICENSE_CONFIG, 
  SAVE_PLAYREADY_LICENSE_CONFIG_START,
  SAVE_PLAYREADY_LICENSE_CONFIG_SUCCESS,
  SAVE_PLAYREADY_LICENSE_CONFIG_FAIL
} from './constants';

export const savePlayreadyLicenseConfig = (configurationId, formData) => ({
  type: SAVE_PLAYREADY_LICENSE_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const savePlayreadyLicenseConfigStart = () => ({
  type: SAVE_PLAYREADY_LICENSE_CONFIG_START,
});

export const savePlayreadyLicenseConfigSuccess = (configurationId, formData) => ({
  type: SAVE_PLAYREADY_LICENSE_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const savePlayreadyLicenseConfigFaill = () => ({
  type: SAVE_PLAYREADY_LICENSE_CONFIG_FAIL,
});
import { 
  SAVE_LICENSE_DURATION_CONFIGURATION,
  SAVE_LICENSE_DURATION_CONFIGURATION_START,
  SAVE_LICENSE_DURATION_CONFIGURATION_SUCCESS,
  SAVE_LICENSE_DURATION_CONFIGURATION_FAIL
} from './constants';

export const saveLicenseDurationConfiguration = (configurationId, formData) => ({
  type: SAVE_LICENSE_DURATION_CONFIGURATION,
  payload: {
    configurationId,
    formData
  }
});

export const saveLicenseDurationConfigurationFail = () => ({
  type: SAVE_LICENSE_DURATION_CONFIGURATION_FAIL,
});

export const saveLicenseDurationConfigurationSuccess = (configurationId, formData) => ({
  type: SAVE_LICENSE_DURATION_CONFIGURATION_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveLicenseDurationConfigurationStart = () => ({
  type: SAVE_LICENSE_DURATION_CONFIGURATION_START,
});
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Line } from "react-chartjs-2";
import Chart from "chart.js";
import { Card, CardHeader, CardBody, NavItem, Nav, Row, Col, Input} from "reactstrap";
import { chartOptions, parseOptions } from "../../variable/charts.js";
import useConstructor from "../../hooks/useConstructor"; 
import styles from './LineChart.module.scss';
import { createLineChartDatasets } from '../../utils/charts';

const LineChart = ({ title, darkMode, data }) => {

  const [options, setOptions] = useState(null);
  const [datasets, setDatasets] = useState([]);
  const [scale, setScale] = useState("hours");
  const [scaleVisibility, setScaleVisibility] = useState(false);
  const [mode, setMode] = useState({
    activeNav: 0,
    name: 'day'
  });
  
  useConstructor(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  })

  useEffect(() => {
    const { options, datasets } = createLineChartDatasets(data, scale);
    setOptions(options);
    setDatasets(datasets)
  }, [data, scale])

  useEffect(() => {
    if (mode.name.toLowerCase() === 'day') {
      setScaleVisibility(false);
    } else {
      setScaleVisibility(true);
    }
  },[mode])

  const toggleNavs = (e, index, name) => {
    if (index !== mode.activeNav) {
      setMode({
        activeNav: index,
        name: name.toLowerCase()
      });
    }
  };

  const handleChangeScale = (e) => {
    setScale(e.target.value);
  }

  const hasMultipleDataSets = () => {
    return datasets && datasets.length > 1;
  }

  return (
    <Col xl="12">
      <Card className={ classnames({ "bg-default": darkMode }) }>
        <CardHeader className={ classnames({ "bg-transparent" : darkMode }) }>
          <Row className="align-items-center">
            <div className="col-12 col-lg-4 pb-2 pb-lg-0">
              <h6 className={classnames("text-uppercase ls-1 mb-1", {
                "text-light": darkMode
              })}>
                Overview
              </h6>
              <h5 className={ classnames("h3 mb-0", {
                "text-white" : darkMode
              })}>{title}</h5>
            </div>
            {
              hasMultipleDataSets() ?
                <div className="col-12 col-lg-8">
                  <Nav className={classnames("justify-content-end float-left float-lg-right", {
                    [styles.filterButtons]: true
                  })} pills>
                    {
                      scaleVisibility ? 
                        (<NavItem className="mr-2 mr-md-0 mb-0 d-none d-lg-block pb-1">
                          <Input type="select" value={scale} onChange={handleChangeScale} className={ classnames("py-2 px-3 nav-link border-0", {
                            [styles.chartResolutionSelect]: true
                          }) }>
                            <option value="hours" label="Scale: Hours"></option>
                            <option value="days" label="Scale: Days"></option>
                          </Input>
                        </NavItem>) : null
                    }

                    {
                      datasets.map((data, index, arr) => (
                        <NavItem className={classnames('mr-2 mr-md-0 mb-0 pb-1', {
                          'switchButton': arr.length - 1 === index
                        })} key={index}>
                          <button 
                            onClick={e => toggleNavs(e, index, data.name)}
                            className={classnames("py-2 px-3 nav-link border-0", {
                              active: mode.activeNav === index
                            })}>
                            <span className="d-md-block">{data.name}</span>
                          </button>
                        </NavItem>
                      ))
                    }

                    {
                      scaleVisibility ? 
                        (<NavItem className={classnames('mr-2 mr-md-0 mb-0 d-lg-none pb-1', {
                          [styles.scaleSwitch]: true
                        })}>
                          <Input type="select" value={scale} onChange={handleChangeScale} className={ classnames("py-2 px-3 nav-link border-0", {
                            [styles.chartResolutionSelect]: true,
                          }) }>
                            <option value="hours" label="Scale: Hours"></option>
                            <option value="days" label="Scale: Days"></option>
                          </Input>
                        </NavItem>) : null
                    }
                  </Nav>
                </div>
                :
                null
            }
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {
              options && datasets ?
                <Line
                  data={datasets[mode.activeNav].data}
                  options={options}
                  id="chart-sales-dark"
                  className="chart-canvas"
                  redraw
                />
                :
                null
            }
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default LineChart;

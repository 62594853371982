import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

var mock = new MockAdapter(axios, { delayResponse: 500 });
 
mock.onGet("/users").reply(200, {
  users: [{ id: 1, name: "John Smith" }],
});

mock.onGet("/drm/6e6gf012-f418-4e78-862d-5710e1ac6216").reply(200, [{
  brandGuid : "6e6gf012-f418-4e78-862d-5710e1ac6216",
  config : {
    fairPlay : {
      licenseConfig : {
        canPersist : true,
        rentalDurationSeconds : 86000,
        leaseDurationSeconds: 72000,
        enforceHdcp: false,
        hdcpType: null,
        offlineStorageDurationSeconds: 60,
        offlinePlaybackDurationSeconds: 180
      },
      systemConfig : {
        ask : "1bc0432fef0",
        certificateClientBase64 : "vHC2iGs4Xzt85XOeLcMfSsaeEnEKe8w9g23mZ/TplcrsEgWyJWl0I5shiIYbNN6H+X0dR3m8gERT/gfdgfdgdfgd",
        certificatePfxBase64 : "sdfkj32h4kj2b3",
        certificatePfxPassword : "daGT%",
        fixedIVHex : "038fcf4d05f82",
        laUrl : "https://example.pl/acquire-license/fairplay",
        dask: "dask example value"
      }
    },
    licenseDurationConfig : {
      expirationSeconds : 86400,
      expirationType : 2,
      fixedExpirationTime: '2020-09-22T23:12:00+00:00'
    },
    playReady : {
      licenseConfig : {
        durationSeconds : 86346,
        isPersistent : true,
        minimumSecurityLevel : 150,
        analogVideoOPL: 1,
        compressedDigitalAudioOPL: 250,
        compressedDigitalVideoOPL: 1,
        uncompressedDigitalAudioOPL: 1,
        uncompressedDigitalVideoOPL: 1,
        firstPlayExpirationSeconds: 1,
        gracePeriodSeconds: 1,
        playerEnablers: [
          "player-f418-5710e1ac6216"
        ],
        // analogVideoOutputProtectionList: [
        //   { guid: "analog-f418", hexData: "hex0data" }
        // ],
        // digitalAudioOutputProtectionList: [
        //   { guid: "digital-5710e1ac6216", hexData: "hex0data" }
        // ]
      },
      systemConfig : {
        laUrl : "https://example.pl/acquire-license/playready",
        playReadyServiceId : "E65BE0885F95",
        serverAuthorizationCertificate : "dOSvkjxbIUWXZMOutOsqPKMXC3Tk6ruG8Fr/LFjtdh3hoAAAAAAAAAAAAAAAAAAAAAAAABAA8AAAAMAAAALQABAAUAAAAQAAAAAQAAAAwAAQAGAAAAYAAAAAEAAQIAAAAAAOR5WbqygoxZ0cstKGP9zFaFDmvadMpphwu88KtKVFJl7PPpTOhAKIdDtfuQQTuM6CV7CM4vbc5iUXCm0TJhtc0AAAACAAAAAQAAABQAAAAHAAAAdAAAAAAAAAAqSW5zeXMgVmlkZW8gVGVjaG5vbG9naWVzIFNwLiB6IG8uby4gc3Auay4AAAAAAAAoUGxheVJlYWR5IFNlcnZlciBEZXBsb3ltZW50IENlcnRpZmljYXRlAAAAAAY0MjcwOAAAAAABAAgAAACQAAEAQLPYfoxmPpTFykG5bwLB89zfPkZPZ42AmF/kknV4q400jZzyvHm+pk81lLghi2rVbjtMYFgw42TLrA3okRqkGf0AAAIAzR3Xc/qIh0WSjJtSLMPMublB8idSv4802llOL0SjIUV17UdZK+87JjEM4Xy1K4KPiV4/gyfzdcmbwwsMRnxGJkNFUlQAAAABAAABsAAAASAAAQABAAAAWJoKCzYyu0Uxo2szjVmR9G8AAAAAAAAAAAAAAAT2iFT1rUponIj1tTCrBpXEE6jtHjFL6Abwe/QRJ0G2fP////8AAAAAAAAAAAAAAAAAAAAAAAEABQAAAAwAAAAAAAEABgAAAGAAAAABAAECAAAAAADNHddz+oiHRZKMm1Isw8y5uUHyJ1K/jzTaWU4vRKMhRXXtR1kr7zsmMQzhfLUrgo+JXj+DJ/N1yZvDCwxGfEYmAAAAAgAAAAEAAAAPAAAABwAAAEwAAAAAAAAACk1pY3Jvc29mdAAAAAAAAB1QbGF5UmVhZHkgU0wwIFNlcnZlciBSb290IENBAAAAAAAAAAgxLjAuMC4xAAABAAgAAACQAAEAQDo4+VVqzH2B3h/t2dmhLYSha5usoaKS5+bHYcPfRvJwW7mnfVjDr1gtWR5JUXYYQ7X9O1Ev2O7DQPbAS0KX3/MAAAIAhk1hz/IlbkIsVos8KAAc+z4VJ2WFhLoFIbebGCjZNt4dgmqPw+bn+nqQ1copRvH2Si77n13P/n5DTrRCk/rFqw==",
        serverAuthorizationKeyPair : "<ECCKeyValue>    <PrivateKey>U5EHtlJpRSA=</PrivateKey>     <PublicKey>5HlZurKCj+5BBO4zoJXsIzi9tzmJRcKbRMmG1zQ==</PublicKey>     <Curve>1</Curve>   </ECCKeyValue>"
      }
    },
    widevine : {
      licenseConfig : {
        allowedTrackTypes : "SD_HD",
        canPersist : false,
        canPlay : true,
        licenseDurationSeconds : 86400,
        playbackDurationSeconds : 86400,
        rentalDurationSeconds : 86400,
        securityLevel : 1,
        hdcp: "HDCP_NONE",
        cgmsFlags: "COPY_FREE"
      },
      systemConfig : {
        googleContentKeyUrl : "https://example.com/cenc/getcontentkey/insys",
        googleLicenseUrl : "https://example.com/cenc/getlicense/insys",
        provider : "test",
        signingIV : "e94e",
        signingKey : "29953a29616011f"
      }
    }
  },
  id : "6e6ee012-f418-4e78-862d",
  keyDerivationServiceConfig : "v/Nksdf/8X42xisIL",
  keyDerivationServiceName : "PlayReadyKeyGenerator",
  name : "test name",
  rules : [
    { 
      guid: "e42b6545-c340fc09c239", 
      name: "pg-mobile", 
      action: 1, 
      validationServiceName: "DrmSignedTokenValidator",
      validationConfig: "74298749282983",
      active: true, 
      // additionDate: "2018-01-10 12:12:43", 
      // createdBy: "J.Nowak", 
      // lastModificationDate: null, 
      // modifiersBy: null,
      pattern: {
        keyIds: ["key-1", "key-2"],
        userTokens: ["token-1", "token2"]
      },
      configOverride: {
        fairPlay : {
          licenseConfig : {
            canPersist : true,
            rentalDurationSeconds : 86000,
            leaseDurationSeconds: 72000,
            enforceHdcp: false,
            hdcpType: null,
            offlineStorageDurationSeconds: 60,
            offlinePlaybackDurationSeconds: 180
          },
          systemConfig : {
            ask : "1d7c4900d6fa",
            certificateClientBase64 : "MII/3eq8Qc6b1TnQdLQjuW5BnZDeWef3vZqNNm05S5gs+zMRtlDOu3h5f2xZx1/OdBkZHsFiFh1wULW8YV5G7r0aChE615FryZzE0dlmiVOCzRzBvgevNffZ5L5aoecWrbQF+Z/Pdo9R7UCAwEAAaOCAggwggIEMB0GA1UdDgQWBBTeKc6gUXLDhvk6ZBeld92rFO8qrTAMBgNVHRMBAf8EAjAAMB8GA1UdIwQYMBaAFGPkR1TLhXFZRiyDrMxEMWRnAyy+MIHiBgNVHSAEgdowgdcwgdQGCSqGSIb3Y2QFATCBxjCBwwYIKwYBBQUHAgIwgbYMgbNSZWxpYW5jZSBvbiB0aGlzIGNlcnRpZmljYXRlIGJ5IGFueSBwYXJ0eSBhc3N1bWVzIGFjY2VwdGFuY2Ugb2YgdGhlIHRoZW4gYXBwbGljYWJsZSBzdGFuZGFyZCB0ZXJtcyBhbmQgY29uZGl0aW9ucyBvZiB1c2UsIGNlcnRpZmljYXRlIHBvbGljeSBhbmQgY2VydGlmaWNhdGlvbiBwcmFjdGljZSBzdGF0ZW1lbnRzLjA1BgNVHR8ELjAsMCqgKKAmhiRodHRwOi8vY3JsLmFwcGxlLmNvbS9rZXlzZXJ2aWNlcy5jcmwwDgYDVR0PAQH/BAQDAgUgMD0GCyqGSIb3Y2QGDQEDAQH/BCsBdjlobHRjeWJxbXFlcGx5dXc5Njhna2VudnJ0b2Zxb3p0Yjk3Y3Qyd3ZyMEkGCyqGSIb3Y2QGDQEEAQH/BDcBZHNpZ2RodG1leXllcnR3dHhpcXlpNTlreGtvcTNra3FxdmRnN29pZzdnZ3hjeWpva2RibmIwMA0GCSqGSIb3DQEBBQUAA4IBAQCy2Ke7nGPHBIHrmShzl4fkIQ+pJjKLRO58wpPrixdqyWnfW64oE8IYGF3SCfUdTm/xuQQlBzxkodS6vfT5geU7KLWSKtCY+LWWEvvRd2Y3pfyTxTsol8NyBof8E5+payfRTvDo4ZTDa6/+BWi1bxnSIRjTfyCzHodUNMaDiI0IwnQ/oszRBE+jk3hi23h4iu23huj/nGbqmEOc1veKWW5pNOX2e6RPZTrTvHC2iGs4Xzt85XOeLcMfSsaeEnEKe8w9g23mZ/TplcrsEgWyJWl0I5shiIYbNN6H+X0dR3m8gERT/gfdgfdgdfgd",
            certificatePfxBase64 : "hdsdadfkj32h4kj2b3",
            certificatePfxPassword : "ffffaGT%",
            fixedIVHex : "1f3a82",
            laUrl : "https://test.pl/acquire-license/fairplay",
            dask: "dask example value"
          }
        },
        licenseDurationConfig : {
          expirationSeconds : 400,
          expirationType : 1,
          fixedExpirationTime: '2019-09-22T23:12:00+00:00'
        },
        playReady : {
          licenseConfig : {
            durationSeconds : 86346,
            isPersistent : true,
            minimumSecurityLevel : 150,
            analogVideoOPL: 1,
            compressedDigitalAudioOPL: 250,
            compressedDigitalVideoOPL: 1,
            uncompressedDigitalAudioOPL: 1,
            uncompressedDigitalVideoOPL: 1,
            firstPlayExpirationSeconds: 1,
            gracePeriodSeconds: 1,
            playerEnablers: [
              "player-f418-4e785710e1ac6216"
            ],
            // analogVideoOutputProtectionList: [
            //   { guid: "analog-f418-4e78-862d-5710e1ac6216", hexData: "hex0data" }
            // ],
            // digitalAudioOutputProtectionList: [
            //   { guid: "digital-f418-4e78-862d-5710e1ac6216", hexData: "hex0data" }
            // ]
          },
          systemConfig : {
            laUrl : "https://example.pl/acquire-license/playready",
            playReadyServiceId : "65BE0885F95",
            serverAuthorizationCertificate : "/LFjtdh3hoAAAAAAAAAAAAAAAAAAAAAAAABAA8AAAAMAAAALQABAAUAAAAQAAAAAQAAAAwAAQAGAAAAYAAAAAEAAQIAAAAAAOR5WbqygoxZ0cstKGP9zFaFDmvadMpphwu88KtKVFJl7PPpTOhAKIdDtfuQQTuM6CV7CM4vbc5iUXCm0TJhtc0AAAACAAAAAQAAABQAAAAHAAAAdAAAAAAAAAAqSW5zeXMgVmlkZW8gVGVjaG5vbG9naWVzIFNwLiB6IG8uby4gc3Auay4AAAAAAAAoUGxheVJlYWR5IFNlcnZlciBEZXBsb3ltZW50IENlcnRpZmljYXRlAAAAAAY0MjcwOAAAAAABAAgAAACQAAEAQLPYfoxmPpTFykG5bwLB89zfPkZPZ42AmF/kknV4q400jZzyvHm+pk81lLghi2rVbjtMYFgw42TLrA3okRqkGf0AAAIAzR3Xc/qIh0WSjJtSLMPMublB8idSv4802llOL0SjIUV17UdZK+87JjEM4Xy1K4KPiV4/gyfzdcmbwwsMRnxGJkNFUlQAAAABAAABsAAAASAAAQABAAAAWJoKCzYyu0Uxo2szjVmR9G8AAAAAAAAAAAAAAAT2iFT1rUponIj1tTCrBpXEE6jtHjFL6Abwe/QRJ0G2fP////8AAAAAAAAAAAAAAAAAAAAAAAEABQAAAAwAAAAAAAEABgAAAGAAAAABAAECAAAAAADNHddz+oiHRZKMm1Isw8y5uUHyJ1K/jzTaWU4vRKMhRXXtR1kr7zsmMQzhfLUrgo+JXj+DJ/N1yZvDCwxGfEYmAAAAAgAAAAEAAAAPAAAABwAAAEwAAAAAAAAACk1pY3Jvc29mdAAAAAAAAB1QbGF5UmVhZHkgU0wwIFNlcnZlciBSb290IENBAAAAAAAAAAgxLjAuMC4xAAABAAgAAACQAAEAQDo4+VVqzH2B3h/t2dmhLYSha5usoaKS5+bHYcPfRvJwW7mnfVjDr1gtWR5JUXYYQ7X9O1Ev2O7DQPbAS0KX3/MAAAIAhk1hz/IlbkIsVos8KAAc+z4VJ2WFhLoFIbebGCjZNt4dgmqPw+bn+nqQ1copRvH2Si77n13P/n5DTrRCk/rFqw==",
            serverAuthorizationKeyPair : "<ECCKeyValue>    <PrivateKey>+AtLYfGU0gb3bpBIXsFds4BylJpRSA=</PrivateKey>     <PublicKey>/3MVoUOa9p0ymmHC7zwq0pUUmXs8+lM6EAoh0O1+5BBO4zoJXsIzi9tzmJRcKbRMmG1zQ==</PublicKey>     <Curve>1</Curve>   </ECCKeyValue>"
          }
        },
        widevine : {
          licenseConfig : {
            allowedTrackTypes : "SD_HD",
            canPersist : false,
            canPlay : true,
            licenseDurationSeconds : 86400,
            playbackDurationSeconds : 86400,
            rentalDurationSeconds : 86400,
            securityLevel : 1,
            hdcp: "HDCP_NONE",
            cgmsFlags: "COPY_FREE"
          },
          systemConfig : {
            googleContentKeyUrl : "https://example.com/cenc/getcontentkey/insys",
            googleLicenseUrl : "https://exanple.com/cenc/getlicense/insys",
            provider : "test",
            signingIV : "4451dca27fdsf2",
            signingKey : "87agfds2341c87f044e97c2d923629953a29616011f"
          }
        }
      }
    },
    { guid: "d8f06e01-f099-4a34-8dc3-989f58eb6f38", name: "pg-www", active: false, action: 2, configOverride: {}, validationServiceName: "DrmSignedTokenValidator", validationConfig: "224444444" },
    { guid: "b08c247b-a4aa-4dbf-b66a-94fab253eae2", name: "iOS", active: true, action: 3, additionDate: "2020-09-10 19:00:43", createdBy: "J.Nowak", lastModificationDate: "2020-11-10 12:12:43", modifiersBy: "E.Adamczak"}
  ]
}]);
 
// axios.get("/users").then(function (response) {
//   console.log(response.data);
// });

export default axios;
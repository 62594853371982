import { 
  SAVE_FAIRPLAY_LICENSE_CONFIG, 
  SAVE_FAIRPLAY_LICENSE_CONFIG_START,
  SAVE_FAIRPLAY_LICENSE_CONFIG_SUCCESS,
  SAVE_FAIRPLAY_LICENSE_CONFIG_FAIL
} from './constants';

export const saveFairplayLicenseConfig = (configurationId, formData) => ({
  type: SAVE_FAIRPLAY_LICENSE_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const saveFairplayLicenseConfigStart = () => ({
  type: SAVE_FAIRPLAY_LICENSE_CONFIG_START,
});

export const saveFairplayLicenseConfigSuccess = (configurationId, formData) => ({
  type: SAVE_FAIRPLAY_LICENSE_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveFairplayLicenseConfigFaill = () => ({
  type: SAVE_FAIRPLAY_LICENSE_CONFIG_FAIL,
});
import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  addPlayerEnablerStart, 
  addPlayerEnablerSuccess,
  addPlayerEnablerFaill,
  deletePlayerEnablerStart, 
  deletePlayerEnablerSuccess,
  deletePlayerEnablerFaill  
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* addPlayerEnablerSaga({ payload: { configurationId, formData } }) {
  console.log(formData);
  yield put(addPlayerEnablerStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(addPlayerEnablerSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(addPlayerEnablerFaill());
    yield put(showErrorModal());
  }
}

export function* deletePlayerEnablerSaga({ payload: { configurationId, formData } }) {
  yield put(deletePlayerEnablerStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(deletePlayerEnablerSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(deletePlayerEnablerFaill());
    yield put(showErrorModal());
  }
}
import React from 'react';

import { Container } from "reactstrap";
import MainFooter from '../../components/Layout/Footers/MainFooter';
import FullPageBackground from '../../components/Layout/Backgrounds/FullPageBackground';
import LayoutRouter from '../../components/Common/LayoutRouter';
import backgroundImage from '../../assets/images/bg-4.jpg';

import './MarketplaceLayout.scss';
import routes from './routes';

const MarketplaceLayout = () => {
  return (
    <div className="content align-items-center pt-5 c-white marketplace pr">
      <FullPageBackground backgroundImage={backgroundImage} backgroundColor={'rgba(0,0,0,0.7)'} />
      <Container className="main-container">
        <LayoutRouter layout="/marketplace" routes={routes} />
      </Container>
      <Container>
        <MainFooter />
      </Container>
    </div>
  )
}
  
export default MarketplaceLayout;
import { 
  ADD_LICENSE_RULE, 
  ADD_LICENSE_RULE_START,
  ADD_LICENSE_RULE_SUCCESS,
  ADD_LICENSE_RULE_FAIL
} from './constants';

export const addLicenseRule = (configurationId, formData) => ({
  type: ADD_LICENSE_RULE,
  payload: {
    configurationId,
    formData
  }
});

export const addLicenseRuleStart = () => ({
  type: ADD_LICENSE_RULE_START,
});

export const addLicenseRuleSuccess = (configurationId, formData) => ({
  type: ADD_LICENSE_RULE_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const addLicenseRuleFaill = () => ({
  type: ADD_LICENSE_RULE_FAIL,
});
import { createSelector } from 'reselect';


export const selectIsDRMDashboardsFetched = createSelector(
  state => state.drmDashboards.isFetched,
  isFetched => isFetched
)

export const selectAllDashboards = createSelector(
  state => state.drmDashboards.dashboards,
  dashboards => dashboards
)

export const selectDashboardsIds = createSelector(
  state => state.drmDashboards.dashboardsIds,
  dashboardsIds => dashboardsIds
)

export const selectAllDashboardsById = createSelector(
  state => state.drmDashboards.dashboardsById,
  dashboardsById => dashboardsById
)

export const makeGetDashboardById = () => createSelector(
  (_, props) => props.dashboardId,
  selectAllDashboardsById,
  (dashboardId, dashboards) => dashboards[dashboardId]
)

import React from 'react';
import {
  Col, Row, Container,
  Card, CardBody, CardFooter,
  Button
} from 'reactstrap';
import classnames from 'classnames';
import { highlightEmails } from '../../utils/formatter';

import { success, error } from './StatusCard.module.scss';

const StatusCard = ({ status, btnText, onClick, body = '', }) => {
  return (
    <Col lg="6" md="8" className="m-auto">
      <Card className="card-signup">
        <CardBody className="m-auto">
          <Container>
            <Row>
              <Col className="text-center">
                <i className={classnames('far', {
                  "fa-times-circle": status === "error",
                  "fa-check-circle": status === "success",
                  [error]: status === "error",
                  [success]: status === "success"
                })}></i>
              </Col>
            </Row>
            <Row>
              <Col sm="10" className="text-center m-auto">
                { status === "success" ? <h1>Success!</h1> : null }
                { status === "error" ? <h1>Error!</h1> : null } 
                <p className="c-black" dangerouslySetInnerHTML={{__html: highlightEmails(body, '#e60050')}}></p>
              </Col>
            </Row>
          </Container>
        </CardBody>
        <CardFooter className="text-center">
          <Button color="marketplace" size="lg" className="btn-round" onClick={onClick}>{btnText}</Button>
        </CardFooter>
      </Card>
    </Col>
  )
}

export default StatusCard;
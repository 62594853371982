import React from 'react';
import classnames from 'classnames'
import { Badge } from 'reactstrap';

const StatusBadge = ({ statusClassName, text }) => (
  <Badge color="" className="badge-dot mr-4">
    <i className={classnames(`${statusClassName}`)} />
    <span className="status">{text}</span>
  </Badge>
);

export default StatusBadge;

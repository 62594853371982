import { put } from 'redux-saga/effects';
import axios from '../../../../api/axiosMockAdapter';
import { 
  saveFairplayLicenseConfigStart, 
  saveFairplayLicenseConfigSuccess,
  saveFairplayLicenseConfigFaill   
} from './actions'

import {
  showProcessingModal,
  showSuccessModal,
  showErrorModal
} from '../../../Modal/actions';

export function* saveFairplayLicenseConfigSaga({ payload: { configurationId, formData } }) {
  yield put(saveFairplayLicenseConfigStart());
  try {
    yield put(showProcessingModal());
    yield axios.get("/users");
    yield put(saveFairplayLicenseConfigSuccess(configurationId, formData));
    yield put(showSuccessModal());

  } catch(error) {
    console.log(error.response);
    yield put(saveFairplayLicenseConfigFaill());
    yield put(showErrorModal());
  }
}
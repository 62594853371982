import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink as NavLinkRRD } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";

import { open } from '../../features/alertModal/alertModalSlice';
import { en } from '../../resources/tenantNotReady';

const More = ({ id, isReady }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!isReady) {
      dispatch(open({ title: en.modal.title, body: en.modal.body }));
    }
  }
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light bc-transparent"
        role="button"
        size="sm"
        color=""
        onClick={handleClick}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      {
        isReady === true ? (
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              to={`/admin/drm/dashboards/${id}`}
              tag={NavLinkRRD}
            >
              More
            </DropdownItem>
          </DropdownMenu>
        ) : <div />
      }
    </UncontrolledDropdown>
  );
}

export default More;
import React from 'react';
import classnames from 'classnames';

import { btnAnimated, success, error, processing } from './AnimatedButton.module.scss' 

const AnimatedButton = ({ onClick, status = 'init' }) => {

  let statusClass = '';

  switch(status) {
    case 'processing':
      statusClass = processing;
      break;
    case 'success':
      statusClass = success;
      break;
    case 'error':
      statusClass = error;
      break;
    default:
      statusClass = '';
  }

  return (
    <button disabled={ status !== 'init' } type="button" className={classnames(`btn-round btn btn-marketplace btn-lg ${btnAnimated}`, statusClass)} onClick={onClick}></button>    
  )
}

export default AnimatedButton;
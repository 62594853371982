import React from 'react';
import {
  Col,
  Card, CardBody, CardFooter,
  Form,
} from 'reactstrap';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { getQueryParam, isEncoded, decode } from '../../utils/url';
import { marketplaceAxiosInstance as axios } from '../../api/axios';

import InputGroup from '../Common/InputGroup';
import AgreeCheckbox from '../Common/AgreeCheckbox';
import AnimatedButton from '../Common/AnimatedButton';

const ContactForm = ({ 
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit, 
  checkboxAgreement,
  status
}) => {
  return (
    <Col lg="5" md="5" className="mr-auto">
      <Card className="pt-4 card-signup">
        <CardBody>
          <Form onSubmit={ handleSubmit }>
            <InputGroup placeholder="First Name" icon="far fa-user" htmlFor="firstName" value={values.firstName} handleChange={handleChange} handleBlur={handleBlur} errors={errors.firstName} />
            <InputGroup placeholder="Last Name" icon="far fa-user-circle" htmlFor="lastName" value={values.lastName} handleChange={handleChange} handleBlur={handleBlur} errors={errors.lastName} />
            <InputGroup placeholder="Email" icon="far fa-envelope" htmlFor="email" value={values.email} handleChange={handleChange} handleBlur={handleBlur} errors={errors.email} />
            <InputGroup placeholder="Telephone number" icon="fas fa-phone-alt" htmlFor="phone" value={values.phone} handleChange={handleChange} handleBlur={handleBlur} errors={errors.phone} />
            <AgreeCheckbox htmlFor="agreement" value={values.agreement} text={checkboxAgreement} handleChange={handleChange} errors={errors.agreement} required />
          </Form>
        </CardBody>
        <CardFooter className="text-center">
          <AnimatedButton status={status} onClick={handleSubmit} />
        </CardFooter>
      </Card>
    </Col>
  )
};

const ContactFormApp = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    agreement: false
  }),
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: Yup.object({
    firstName: Yup.string()
      .required('Required')
      .min(1),
    lastName: Yup.string()
      .required('Required')
      .min(1),
    email: Yup.string()
      .required('Required')
      .min(1),
    phone: Yup.string()
      .required('Required')
      .min(1),
    agreement: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted.")
  }),
  handleSubmit: async (formData, { setStatus, props: { history } }) => {
    
    try {
      setStatus('processing');
      
      const { firstName, lastName, email, phone } = formData;
      let token = getQueryParam('token');

      if (isEncoded(token)) {
        token = decode(token);
      }

      const response = await axios.post('/Marketplace/CreateAccount', {
        token: token,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone
      })
      
      const { result: { success } } = response.data;

      if (success) {
        setStatus('success');
        setTimeout(() => {
          history.push('/marketplace/drm/success');
        }, 2000);
      } else {
        setStatus('error');
        setTimeout(() => {
          history.push('/marketplace/drm/error');
        }, 2000);
      }
    } catch(error) {
      setStatus('error');
      setTimeout(() => {
        history.push('/marketplace/drm/error');
      }, 2000);
    }
  }
})(ContactForm);

export default ContactFormApp;

import { 
  SAVE_WIDEVINE_SYSTEM_CONFIG, 
  SAVE_WIDEVINE_SYSTEM_CONFIG_START,
  SAVE_WIDEVINE_SYSTEM_CONFIG_SUCCESS,
  SAVE_WIDEVINE_SYSTEM_CONFIG_FAIL
} from './constants';

export const saveWidevineSystemConfig = (configurationId, formData) => ({
  type: SAVE_WIDEVINE_SYSTEM_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const saveWidevineSystemConfigStart = () => ({
  type: SAVE_WIDEVINE_SYSTEM_CONFIG_START,
});

export const saveWidevineSystemConfigSuccess = (configurationId, formData) => ({
  type: SAVE_WIDEVINE_SYSTEM_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const saveWidevineSystemConfigFaill = () => ({
  type: SAVE_WIDEVINE_SYSTEM_CONFIG_FAIL,
});
import { 
  SAVE_PLAYREADY_SYSTEM_CONFIG, 
  SAVE_PLAYREADY_SYSTEM_CONFIG_START,
  SAVE_PLAYREADY_SYSTEM_CONFIG_SUCCESS,
  SAVE_PLAYREADY_SYSTEM_CONFIG_FAIL
} from './constants';

export const savePlayreadySystemConfig = (configurationId, formData) => ({
  type: SAVE_PLAYREADY_SYSTEM_CONFIG,
  payload: {
    configurationId,
    formData
  }
});

export const savePlayreadySystemConfigStart = () => ({
  type: SAVE_PLAYREADY_SYSTEM_CONFIG_START,
});

export const savePlayreadySystemConfigSuccess = (configurationId, formData) => ({
  type: SAVE_PLAYREADY_SYSTEM_CONFIG_SUCCESS,
  payload: {
    configurationId,
    formData
  }
});

export const savePlayreadySystemConfigFaill = () => ({
  type: SAVE_PLAYREADY_SYSTEM_CONFIG_FAIL,
});